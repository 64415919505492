import * as distributorsStandard from 'app/mocks/data/machines/all/standard/distributors'

export const distributorDiagnostic1 = {
    machine: {
        id: distributorsStandard.distributorStandard1.id,
        type: distributorsStandard.distributorStandard1.category,
    },
    internet: {
        is_online: true,
        last_loss_connection: '2020-12-27 08:12:49.219717',
        last_heartbeat_connection: '2023-10-17 08:26:49.219717',
    },
}

export const distributorDiagnostic2 = {
    machine: {
        id: distributorsStandard.distributorStandard2.id,
        type: distributorsStandard.distributorStandard2.category,
    },
    internet: {
        is_online: false,
        last_loss_connection: '2019-08-20 08:12:49.219717',
        last_heartbeat_connection: '2020-09-07 08:26:49.219717',
    },
}
