import { http, HttpResponse } from 'msw'

import { allSuppliers } from 'app/mocks/data/catalogue/suppliers'
import { api, isNumeric } from 'app/utils/constants'

export const getAllSuppliers = http.get(
    `${process.env.REACT_APP_API_ADDRESS}/${api.catalogue.SUPPLIERS}`,
    ({ request }) => {
        const url = new URL(request.url)
        const limit = url.searchParams.get('limit')
        const offset = url.searchParams.get('offset')
        const managerIdsFilter = url.searchParams.get('client__id__in')
        const nameFilter = url.searchParams.get('name__icontains')

        let allSuppliersFiltered = allSuppliers

        // Manager filter
        if (!!managerIdsFilter) {
            const managerIdsSplit = managerIdsFilter.split(',')

            allSuppliersFiltered = allSuppliersFiltered.filter((supplier) =>
                managerIdsSplit.includes(supplier.client.id.toString()),
            )
        }

        // Name filter
        if (!!nameFilter) {
            allSuppliersFiltered = allSuppliersFiltered.filter((supplier) =>
                supplier.name.toLowerCase().includes(nameFilter.toLowerCase()),
            )
        }

        // Pagination
        const limitArray =
            allSuppliersFiltered.length > limit
                ? offset + limit
                : allSuppliersFiltered.length

        const results =
            isNumeric(offset) && isNumeric(limit)
                ? allSuppliersFiltered.slice(offset, limitArray)
                : allSuppliersFiltered

        const next =
            isNumeric(offset) &&
            isNumeric(limit) &&
            offset + results.length < allSuppliersFiltered.length
                ? 'again'
                : undefined

        return HttpResponse.json({
            count: allSuppliersFiltered.length,
            next,
            results,
        })
    },
)
