import * as managers from 'app/mocks/data/park/managers/list'

export const deliveryMan1 = {
    id: 1,
    client: [managers.manager1],
    last_name: 'DUCOBUS',
    first_name: 'Franck',
    serial_number: '4902L',
    card_id: 'BEST43',
}

export const deliveryMan2 = {
    id: 2,
    client: [managers.manager1, managers.manager2],
    last_name: 'MAJOR',
    first_name: 'John',
    serial_number: '117',
    card_id: 'SPARTA117',
}

export const deliveryMan3 = {
    id: 3,
    client: [managers.manager2],
    last_name: 'CORT',
    first_name: 'Anna',
    serial_number: '11709',
    card_id: 'ECHO117',
}

export const deliveryMan4 = {
    id: 4,
    client: [managers.manager5],
    last_name: 'COKE',
    first_name: 'Pitt',
    serial_number: '140564',
    card_id: 'COKE001',
}

export const deliveryMan5 = {
    id: 5,
    client: [managers.manager7, managers.manager2],
    last_name: 'CANTERO',
    first_name: 'Claire',
    serial_number: '09965263',
    card_id: 'CLC09134',
}

export const deliveryMan6 = {
    id: 6,
    client: [managers.manager8, managers.manager10],
    last_name: 'LOPEZ',
    first_name: 'Charles',
    serial_number: '1086540',
    card_id: 'PCOK308R',
}

export const deliveryMan7 = {
    id: 7,
    client: [managers.manager1],
    last_name: 'CLARK',
    first_name: 'Sophia',
    serial_number: '1086547',
    card_id: 'AANLPFDP',
}

export const deliveryMan8 = {
    id: 8,
    client: [managers.manager7],
    last_name: 'SMITH',
    first_name: 'James',
    serial_number: '1086548',
    card_id: '0LNJHW55',
}

export const deliveryMan9 = {
    id: 9,
    client: [managers.manager15],
    last_name: 'WILSON',
    first_name: 'Charles',
    serial_number: '1086549',
    card_id: 'BQ4NHH9F',
}

export const deliveryMan10 = {
    id: 10,
    client: [managers.manager6],
    last_name: 'JONES',
    first_name: 'Sophia',
    serial_number: '1086550',
    card_id: 'JEOLU93N',
}

export const deliveryMan11 = {
    id: 11,
    client: [managers.manager15, managers.manager9],
    last_name: 'SMITH',
    first_name: 'Martin',
    serial_number: '1086551',
    card_id: 'YH60TYDH',
}

export const deliveryMan12 = {
    id: 12,
    client: [managers.manager15, managers.manager6, managers.manager19],
    last_name: 'CLARK',
    first_name: 'Mia',
    serial_number: '1086552',
    card_id: '8OEEFI9S',
}

export const deliveryMan13 = {
    id: 13,
    client: [managers.manager12, managers.manager21],
    last_name: 'CLARK',
    first_name: 'James',
    serial_number: '1086553',
    card_id: '1Y1DVX6P',
}

export const deliveryMan14 = {
    id: 14,
    client: [managers.manager8],
    last_name: 'CLARK',
    first_name: 'Emma',
    serial_number: '1086554',
    card_id: 'HJVW084C',
}

export const deliveryMan15 = {
    id: 15,
    client: [managers.manager15, managers.manager6, managers.manager19],
    last_name: 'MARTIN',
    first_name: 'Laura',
    serial_number: '1086555',
    card_id: '7UOEIZBZ',
}

export const deliveryMan16 = {
    id: 16,
    client: [managers.manager6],
    last_name: 'MOINEAU',
    first_name: 'Killian',
    serial_number: '1087575',
    card_id: '7UOADZBZ',
}

export const deliveryMan17 = {
    id: 17,
    client: [managers.manager15],
    last_name: 'SERRE',
    first_name: 'Pierre-Antoine',
    serial_number: '2074234',
    card_id: '4U548AD5',
}

export const deliveryMan18 = {
    id: 18,
    client: [managers.manager1],
    last_name: 'BACQUE',
    first_name: 'Camille',
    serial_number: '3287834',
    card_id: '98AD8C8F',
}

export const deliveryMan19 = {
    id: 19,
    client: [managers.manager3],
    last_name: 'GALLOIS',
    first_name: 'Abelin',
    serial_number: '108',
    card_id: 'ADD59FA2',
}

export const deliveryMan20 = {
    id: 20,
    client: [managers.manager5],
    last_name: 'HENNEQUIN',
    first_name: 'Lilian',
    serial_number: '326',
    card_id: '03CD25AD',
}

export const deliveryMan21 = {
    id: 21,
    client: [managers.manager4],
    last_name: 'CHABERT',
    first_name: 'Antoine',
    serial_number: '143',
    card_id: '220ACCD6',
}

export const deliveryMan22 = {
    id: 22,
    client: [managers.manager2],
    last_name: 'BOULET',
    first_name: 'Arthur',
    serial_number: '215',
    card_id: '00C0D00A',
}

export const deliveryMan23 = {
    id: 23,
    client: [managers.manager2],
    last_name: 'OUVARD',
    first_name: 'Gaylord',
    serial_number: '212',
    card_id: '03CDAA21',
}

export const deliveryMan24 = {
    id: 24,
    client: [managers.manager1],
    last_name: 'BACHELOT',
    first_name: 'Jean-Joël',
    serial_number: '201',
    card_id: '03E66EAD',
}

export const deliveryMan25 = {
    id: 25,
    client: [managers.manager5],
    last_name: 'PLOUFFE',
    first_name: 'Célia',
    serial_number: '145',
    card_id: 'EB6BA9D7',
}
