import { z } from 'zod'

/** Country enum. */
export const Country = z.enum([
    'BELGIUM',
    'FRANCE',
    'GERMANY',
    'ITALY',
    'LUXEMBOURG',
    'NETHERLANDS',
    'PORTUGAL',
    'SPAIN',
    'SWITZERLAND',
    'UNITED_KINGDOM',
    'UNITED_STATES_OF_AMERICA',
])

/** Site of a client that contains machines. */
export const Site = z.object({
    /** ID of the site. */
    id: z.number().positive(),

    /** Name of the site. */
    name: z.string().min(1),

    /** Description of the site. */
    description: z.string().optional(),

    /** Address of the site. */
    address: z.string().min(1),

    /** City of the site. */
    city: z.string().min(1),

    /** Country of the site. */
    country: z.string().min(1),

    /** Postal code of the site. */
    postal_code: z.number().positive(),

    /** Latitude of the site. */
    latitude: z.number(),

    /** Longitude of the site. */
    longitude: z.number(),

    /** Building floor of the site. */
    building_floor: z.string().optional(),

    /** Furthe information about the site. */
    further_information: z.string().optional(),

    /** Supply days of the site. */
    supply_days: z.string().optional(),
})
