import { z } from 'zod'

/** Origin of creation of the Konvive. */
export const OriginKonvive = z.enum(['IMPORT', 'KONVIVES', 'MANUAL'])

/** Error type when importing the Konvive users. */
export const ErrorKonviveImport = z.enum([
    'CARD_ID',
    'EMAIL',
    'EMAIL_IN_USE',
    'MIFARE_CODE',
    'NUMERIC_TYPE',
    'STRING_TYPE',
])

/** The final consumer of a machine with an account. */
export const Konvive = z.object({
    /** The ID of the konvive. */
    id: z.number().positive(),

    /** The first name of the konvive. */
    first_name: z.string().min(1),

    /** The last name of the konvive. */
    last_name: z.string().min(1).toUpperCase(),

    /** The email of the konvive. */
    email: z.string().email(),

    /** The private card used by the konvive. */
    card_number: z.string().min(1).optional(),
})
