import { http, HttpResponse } from 'msw'
import { StatusCodes } from 'http-status-codes'

import { api } from 'app/utils/constants'

export const createSupplier = http.post(
    `${process.env.REACT_APP_API_ADDRESS}/${api.catalogue.SUPPLIERS}`,
    async ({ request }) => {
        const data = await request.json()

        // Wrong data
        if (!data) {
            return new HttpResponse(null, {
                status: StatusCodes.UNPROCESSABLE_ENTITY,
            })
        }

        const supplierCreated = {
            id: 999,
            ...data,
        }

        return HttpResponse.json(supplierCreated, {
            status: StatusCodes.CREATED,
        })
    },
)
