import { makeStyles } from '@material-ui/core'

import { joinStyles } from 'app/utils/theme'

const useStyles = makeStyles({
    root: {
        display: 'grid',
    },
})

export const Grid = ({ className, ...properties }) => {
    const classes = useStyles()

    return (
        <div
            className={joinStyles(classes.root, className)}
            {...properties}
        />
    )
}
