import {
    ProgressBar as ReactAriaProgressBar,
    Label,
} from 'react-aria-components'
import { makeStyles } from '@material-ui/core'
import { joinStyles, palette, various } from 'app/utils/theme'
import { Flex } from 'app/ui/Flex'

const useStyles = makeStyles({
    '@keyframes indeterminate': {
        from: {
            transform: 'translateX(-100%)',
        },
        to: {
            transform: 'translateX(250px)',
        },
    },

    root: {
        '&:not([aria-valuenow]) $fill': {
            animation: '$indeterminate 2s linear infinite',
            willChange: 'transform',
        },
    },

    textGroup: {
        justifyContent: 'space-between',
        marginBottom: '10px',
    },

    bar: {
        overflow: 'hidden',
        height: '10px',
        borderRadius: various.borderRadius,
        boxShadow: various.boxShadow.component,
    },

    fill: {
        height: '100%',
        backgroundColor: palette.primary.main,
    },
})

export const ProgressBar = ({ className, label, ...properties }) => {
    const classes = useStyles()

    return (
        <ReactAriaProgressBar {...properties}>
            {({ percentage, valueText }) => (
                <div
                    className={joinStyles(classes.root, className)}
                    aria-valuenow={properties.value}
                >
                    <Flex className={classes.textGroup}>
                        <Label>{label}</Label>

                        <span className='value'>{valueText}</span>
                    </Flex>

                    {/* Progress */}
                    <div className={classes.bar}>
                        <div
                            className={classes.fill}
                            style={{ width: `${percentage}%` }}
                        />
                    </div>
                </div>
            )}
        </ReactAriaProgressBar>
    )
}
