import * as managers from 'app/mocks/data/park/managers/list'
import * as suppliers from 'app/mocks/data/catalogue/suppliers/list'

export const product1 = {
    id: 1,
    name: 'Ananas Dole',
    client: managers.manager1,
    supplier: suppliers.supplier1,
    category: {
        id: 103,
        name: 'Dessert',
        priority: 1,
        client: 1,
    },
    subcategory: {
        id: 110,
        name: 'Desserts',
        priority: 1,
        client: 1,
    },
    price: 7,
    allergens: [],
    purchase_price: 5,
    valid_number_of_days: 20,
    tva: 10,
    description:
        "Des morceaux de pêche délicieusement fondants et naturellement sucrés, dans une coupelle avec pic inclus : idéal en dessert ou en snack où vous voulez (au bureau, au parc, après le sport, ...). Sans sucre ajouté, c'est un aliment naturel et sain. C'est 1 portion de fruit sur les 5 recommandées chaque jour !",
    ean: '789935795125848',
    client_serial: 'D1A203B659',
    photo: 'https://apis-dev.e-thik.com/media/products/dole.webp.200x200_q75.jpg',
    packaging_photo:
        'https://apis-dev.e-thik.com/media/products_packaging/dole.webp.200x200_q75.jpg',
    weight: 200,
    is_sold_in_lots: false,
    disabled: false,
    luncheon_vouchers: false,
    energy_kj: 251,
    energy_kcal: 60,
    carbs: 14,
    sugars: 13,
    fibers: 1,
    proteins: 1.4,
    salt: 1.4,
    needs_cooking: false,
    mass_measure_1: 214,
    mass_measure_2: 216,
    mass_measure_3: 218,
    mass_measure_4: 900,
    mass_measure_5: 219,
    age_limit: false,
}

export const product2 = {
    id: 2,
    name: 'AvocadoTruite (8 pcs)',
    client: managers.manager1,
    supplier: suppliers.supplier1,
    category: {
        id: 278,
        name: 'California Makis',
        priority: 1,
        client: 1,
    },
    subcategory: {
        id: 213,
        name: 'Poisson ',
        priority: 1,
        client: 1,
    },
    price: 6.45,
    allergens: [
        {
            id: 373,
            name: 'Lactose',
            priority: 1,
        },
    ],
    purchase_price: 1,
    valid_number_of_days: 2,
    tva: 5.5,
    description:
        '8 pièces California Makis à base de Riz assaisonné, Truite fraiche Française, Avocat, Fromage frais, Oignons frits',
    ean: '3770033735008',
    client_serial: 'ADZ52654S3',
    photo: 'https://apis-dev.e-thik.com/media/products/e-thik_dev_17073234358966522.jpeg.200x200_q75.jpg',
    packaging_photo:
        'https://apis-dev.e-thik.com/media/products/e-thik_dev_packaging_17073234358968472.jpeg.200x200_q75.jpg',
    weight: 168,
    is_sold_in_lots: false,
    lot_size: 0,
    disabled: false,
    luncheon_vouchers: false,
    energy_kj: 2035.74,
    energy_kcal: 320.18,
    saturated_fats: 4.25,
    carbs: 38.25,
    sugars: 9.12,
    fibers: 2.54,
    proteins: 12.41,
    salt: 2.33,
    needs_cooking: false,
    age_limit: false,
}

export const product3 = {
    id: 3,
    name: 'Badoit',
    client: managers.manager1,
    supplier: suppliers.supplier2,
    category: {
        id: 6,
        name: 'Boissons',
        priority: 1,
        client: 1,
    },
    price: 2.6,
    allergens: [],
    purchase_price: 1.5,
    valid_number_of_days: 90,
    tva: 10,
    description: 'TARATARA',
    ean: '1',
    client_serial: 'PDQ8569F67',
    photo: 'https://apis-dev.e-thik.com/media/products/Badoit1.png.200x200_q75.png',
    packaging_photo:
        'https://apis-dev.e-thik.com/media/products_packaging/Badoit1.png.200x200_q75.png',
    weight: 0,
    volume: 10,
    is_sold_in_lots: false,
    lot_size: 6,
    disabled: false,
    luncheon_vouchers: false,
    needs_cooking: false,
    mass_measure_1: 555,
    mass_measure_2: 555,
    mass_measure_3: 555,
    mass_measure_4: 555,
    mass_measure_5: 555,
    nutriscore: 'A',
    age_limit: false,
}

export const product4 = {
    id: 4,
    name: 'Basilic Poulet (8 pcs)',
    client: managers.manager1,
    supplier: suppliers.supplier2,
    category: {
        id: 311,
        name: 'Nos California Makis',
        priority: 1,
        client: 22,
    },
    subcategory: {
        id: 269,
        name: 'Poulet',
        priority: 1,
        client: 22,
    },
    price: 5.95,
    allergens: [
        {
            id: 401,
            name: 'Gluten + Lactose',
            priority: 1,
        },
    ],
    purchase_price: 1,
    valid_number_of_days: 2,
    tva: 5.5,
    description:
        '8 pièces California Makis à base de Riz assaisonné, Algue Nori Grade A, Poulet (halal) Mariné (marinade maison), Avocat, Pomme, Mayonnaise Vegan, Basilic, Oignons frits',
    ean: '3770033735084',
    client_serial: 'ALK299232J',
    photo: 'https://apis-dev.e-thik.com/media/products/test_17073826068324177.jpeg.200x200_q75.jpg',
    packaging_photo:
        'https://apis-dev.e-thik.com/media/products/test_packaging_17073826068325953.jpeg.200x200_q75.jpg',
    weight: 185,
    is_sold_in_lots: false,
    lot_size: 0,
    disabled: false,
    luncheon_vouchers: false,
    energy_kj: 2445.74,
    energy_kcal: 363.58,
    saturated_fats: 3.55,
    carbs: 40.03,
    sugars: 9.22,
    fibers: 2.44,
    proteins: 15.43,
    salt: 1.98,
    needs_cooking: false,
    age_limit: false,
}

export const product5 = {
    id: 5,
    name: "Beignets d'aubergines et feta",
    client: managers.manager1,
    supplier: suppliers.supplier2,
    category: {
        id: 73,
        name: 'Apero du chef Bio',
        priority: 5,
        client: 14,
    },
    price: 6,
    allergens: [],
    purchase_price: 1,
    valid_number_of_days: 30,
    tva: 5.5,
    description: '',
    ean: '3760198780104',
    client_serial: 'LDZ8323PDA',
    photo: 'https://apis-dev.e-thik.com/media/products/16_aubergine_.jpg.200x200_q75.jpg',

    packaging_photo:
        'https://apis-dev.e-thik.com/media/products_packaging/16_aubergine_.jpg.200x200_q75.jpg',
    weight: 160,
    is_sold_in_lots: false,
    disabled: false,
    luncheon_vouchers: false,
    needs_cooking: false,
    age_limit: false,
}
