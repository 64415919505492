import { Fragment, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Link } from 'react-router-dom'
import { Button, Tree, TreeItem, TreeItemContent } from 'react-aria-components'
import { makeStyles } from '@material-ui/core'
import { GiVendingMachine } from 'react-icons/gi'
import {
    MdAccountBalance,
    MdAssessment,
    MdKeyboardArrowRight,
    MdMenuBook,
    MdPeople,
    MdReceipt,
    MdSettings,
    MdStore,
} from 'react-icons/md'

import { Divider } from 'app/ui'

import { hasPermission } from 'app/utils/app/permissions'
import { joinStyles, palette, themeMultiply, various } from 'app/utils/theme'
import { permissions, roles, urls } from 'app/utils/constants'

const useStyles = makeStyles((theme) => ({
    root: {
        zIndex: 100,
        position: 'absolute',
        bottom: '0',
        overflow: 'auto',
        width: '250px',
        minWidth: '250px',
        height: '90%',
        margin: '10px',
        padding: `${themeMultiply(various.padding, 2)} 0`,
        color: palette.secondary.main,
        backgroundColor: palette.primary.main,
        borderRadius: various.borderRadius,
        boxShadow: various.boxShadow.component,
        [theme.breakpoints.up('lg')]: {
            position: 'unset',
            bottom: 'unset',
            height: 'unset',
        },
    },

    item: {
        '&[data-expanded] > div > button > :last-child': {
            transform: 'rotate(90deg)',
        },
    },

    itemButton: {
        all: 'unset',
        boxSizing: 'border-box',
        cursor: 'pointer',
        display: 'flex',
        alignItems: 'center',
        // TODO: Find why not 100%
        width: '96%',
        margin: '0 5px',
        padding: '7px 15px',
        fontSize: '1.2rem',
        fontWeight: 'bold',
        borderRadius: '5px',
        '&:hover': {
            backgroundColor: `hsla(${palette.primaryRaw.light}, 0.5)`,
        },
    },

    itemName: {
        marginLeft: '15px',
    },

    itemChevron: {
        marginLeft: 'auto',
    },

    itemSelected: {
        backgroundColor: `${palette.primary.light} !important`,
    },

    link: {
        display: 'block',
        padding: '5px 20px',
        paddingLeft: '54px',
        color: palette.secondary.main,
        borderRadius: '5px',
        textDecoration: 'unset',
        '&:hover': {
            textDecoration: 'underline',
        },
    },

    linkSelected: {
        fontWeight: 'bold',
        textDecoration: 'unset !important',
    },
}))

export const Sidebar = () => {
    const classes = useStyles()
    const { t } = useTranslation()

    const [expandedKeys, setExpandedKeys] = useState([])

    const pathname = window.location.pathname
    const actualItem = `/${pathname.split('/')[1]}`

    const permissionsOld = JSON.parse(localStorage.getItem('listGroups'))
    const isAdmin = permissionsOld?.is_superuser || permissionsOld?.is_staff
    const isManager = permissionsOld?.groups.some(
        (group) => group.name === roles.MANAGER,
    )

    const park = {
        name: t('park'),
        icon: <MdStore />,
        index: urls.park.INDEX,
        pages: [
            {
                name: t('managers'),
                link: urls.park.managers.LIST,
                canView:
                    isAdmin ||
                    isManager ||
                    hasPermission(permissions.park.managers.READ),
            },
            {
                name: t('clients'),
                link: urls.park.clients.LIST,
                canView:
                    isAdmin ||
                    isManager ||
                    hasPermission(permissions.park.clients.READ),
            },
            {
                name: t('sites'),
                link: urls.park.sites.INDEX,
                canView:
                    isAdmin ||
                    isManager ||
                    hasPermission(permissions.park.sites.READ),
            },
        ],
    }

    const machines = {
        name: t('machines'),
        icon: <GiVendingMachine />,
        index: urls.machines.INDEX,
        pages: [
            {
                name: t('all'),
                link: urls.machines.ALL,
                canView:
                    isAdmin ||
                    isManager ||
                    hasPermission(permissions.machines.all.READ),
            },
            {
                name: t('microwaves'),
                link: urls.machines.microwaves.LIST,
                canView:
                    isAdmin ||
                    isManager ||
                    hasPermission(permissions.machines.all.READ),
            },
            {
                name: t('planograms'),
                link: urls.machines.planograms.LIST,
                canView:
                    isAdmin ||
                    isManager ||
                    hasPermission(permissions.machines.planograms.READ),
            },
        ],
    }

    const catalogue = {
        name: t('catalogue'),
        icon: <MdMenuBook />,
        index: urls.catalogue.INDEX,
        pages: [
            {
                name: t('suppliers'),
                link: urls.catalogue.suppliers.LIST,
                canView:
                    isAdmin ||
                    isManager ||
                    hasPermission(permissions.catalogue.suppliers.READ),
            },
            {
                name: t('products'),
                link: urls.catalogue.PRODUCTS,
                canView:
                    isAdmin ||
                    isManager ||
                    hasPermission(permissions.catalogue.products.READ),
            },
            {
                name: t('priceTables'),
                link: urls.catalogue.PRICE_TABLES,
                canView:
                    isAdmin ||
                    isManager ||
                    hasPermission(permissions.catalogue.priceTable.READ),
            },
            {
                name: t('discounts'),
                link: urls.catalogue.DISCOUNTS,
                canView:
                    isAdmin ||
                    isManager ||
                    hasPermission(permissions.catalogue.discounts.READ),
            },
            {
                name: 'Stats',
                link: urls.catalogue.STATS,
                canView:
                    isAdmin ||
                    isManager ||
                    hasPermission(permissions.catalogue.products.READ),
            },
        ],
    }

    const transactions = {
        name: t('transactions'),
        icon: <MdAccountBalance />,
        index: urls.transactions.INDEX,
        pages: [
            {
                name: t('sales'),
                link: urls.transactions.SALES,
                canView:
                    isAdmin ||
                    isManager ||
                    hasPermission(permissions.transactions.sales.READ),
            },
            {
                name: t('refunds'),
                link: urls.transactions.REFUNDS,
                canView:
                    isAdmin ||
                    isManager ||
                    hasPermission(permissions.transactions.refunds.READ),
            },
            {
                name: t('refills'),
                link: urls.transactions.REFILLS,
                canView:
                    isAdmin ||
                    isManager ||
                    hasPermission(permissions.transactions.refills.READ),
            },
            {
                name: t('blockedCards'),
                link: urls.transactions.BLOCKED_CARDS,
                canView: isAdmin || isManager,
            },
        ],
    }

    const operations = {
        name: t('operations'),
        icon: <MdReceipt />,
        index: urls.operations.INDEX,
        pages: [
            {
                name: t('deliveries'),
                link: urls.operations.DELIVERIES,
                canView:
                    isAdmin ||
                    isManager ||
                    hasPermission(permissions.operations.deliveries.READ),
            },
            {
                name: t('interventions'),
                link: urls.operations.INTERVENTIONS,
                canView:
                    isAdmin ||
                    isManager ||
                    hasPermission(permissions.operations.interventions.READ),
            },
            {
                name: t('incidents'),
                link: urls.operations.INCIDENTS,
                canView:
                    isAdmin ||
                    isManager ||
                    hasPermission(permissions.operations.incidents.READ),
            },
            {
                name: t('incidentsRabbitmq'),
                link: urls.operations.INCIDENTS_RABBITMQ,
                canView: isAdmin,
            },
            {
                name: t('encodedProducts'),
                link: urls.operations.ENCODED_PRODUCTS,
                canView:
                    isAdmin ||
                    isManager ||
                    hasPermission(permissions.operations.encodedProducts.READ),
            },
            {
                name: t('destocking'),
                link: urls.operations.DESTOCKING,
                canView:
                    isAdmin ||
                    isManager ||
                    hasPermission(permissions.operations.destocking.READ),
            },
        ],
    }

    const reports = {
        name: t('reports'),
        icon: <MdAssessment />,
        index: urls.reports.INDEX,
        pages: [
            {
                name: t('supply'),
                link: urls.reports.SUPPLY,
                canView: isAdmin || isManager,
            },
            {
                name: t('service'),
                link: urls.reports.service.LIST,
                canView: isAdmin || isManager,
            },
        ],
    }

    const users = {
        name: t('users'),
        icon: <MdPeople />,
        index: urls.users.INDEX,
        pages: [
            {
                name: t('profiles'),
                link: urls.users.profiles.LIST,
                canView:
                    isAdmin ||
                    isManager ||
                    hasPermission(permissions.users.profiles.READ),
            },
            {
                name: 'Konsole',
                link: urls.users.konsole.LIST,
                canView:
                    isAdmin ||
                    isManager ||
                    hasPermission(permissions.users.konsole.READ),
            },
            {
                name: t('technicians'),
                link: urls.users.technicians.LIST,
                canView:
                    isAdmin ||
                    isManager ||
                    hasPermission(permissions.users.technicians.READ),
            },
            {
                name: t('deliveryMen'),
                link: urls.users.deliveryMen.LIST,
                canView:
                    isAdmin ||
                    isManager ||
                    hasPermission(permissions.users.deliveryMen.READ),
            },
            {
                name: t('services'),
                link: urls.users.services.LIST,
                canView: isAdmin || isManager,
            },
            {
                name: 'Konvives',
                link: urls.users.KONVIVES,
                canView:
                    isAdmin ||
                    isManager ||
                    hasPermission(permissions.users.konvives.READ),
            },
        ],
    }

    const settings = {
        name: t('settings'),
        icon: <MdSettings />,
        index: urls.settings.INDEX,
        pages: [
            {
                name: t('theme'),
                link: urls.settings.THEME,
                canView:
                    isAdmin ||
                    isManager ||
                    hasPermission(permissions.settings.theme.READ),
            },
            {
                name: t('categories'),
                link: urls.settings.CATEGORIES,
                canView:
                    isAdmin ||
                    isManager ||
                    hasPermission(permissions.settings.productCategories.READ),
            },
            {
                name: t('email'),
                link: urls.settings.EMAIL,
                canView:
                    isAdmin ||
                    isManager ||
                    hasPermission(permissions.settings.email.READ),
            },
            {
                name: t('ticket'),
                link: urls.settings.TICKET,
                canView:
                    isAdmin ||
                    isManager ||
                    hasPermission(permissions.settings.ticket.READ),
            },
        ],
    }

    const groups = [
        park,
        machines,
        catalogue,
        transactions,
        operations,
        reports,
        users,
        settings,
    ].filter((group) => {
        group.pages = group.pages.filter((page) => page.canView)

        return group.pages.length > 0
    })

    // Reset the expanded keys when the path change
    useEffect(() => {
        setExpandedKeys([actualItem])
    }, [actualItem])

    return (
        <nav className={classes.root}>
            <Tree
                expandedKeys={expandedKeys}
                onExpandedChange={setExpandedKeys}
                aria-label={t('navigationBar')}
            >
                {groups.map((group, index) => (
                    <Fragment key={group.index}>
                        <TreeItem
                            id={group.index}
                            className={classes.item}
                            textValue={group.name}
                        >
                            {/* Group */}
                            <TreeItemContent>
                                <Button
                                    className={joinStyles(
                                        classes.itemButton,
                                        pathname.startsWith(group.index) &&
                                            classes.itemSelected,
                                    )}
                                    slot='chevron'
                                >
                                    {group.icon}

                                    <span className={classes.itemName}>
                                        {group.name}
                                    </span>

                                    <MdKeyboardArrowRight
                                        className={classes.itemChevron}
                                    />
                                </Button>
                            </TreeItemContent>

                            {/* Pages */}
                            {group.pages.map((page) => (
                                <TreeItem
                                    key={page.link}
                                    textValue={page.name}
                                >
                                    <TreeItemContent>
                                        <Link
                                            className={joinStyles(
                                                classes.link,
                                                pathname.startsWith(
                                                    page.link,
                                                ) && classes.linkSelected,
                                            )}
                                            to={page.link}
                                        >
                                            {page.name}
                                        </Link>
                                    </TreeItemContent>
                                </TreeItem>
                            ))}
                        </TreeItem>

                        {/* Divider */}
                        {index + 1 < groups.length && (
                            <TreeItem
                                textValue={t('divider')}
                                isDisabled
                            >
                                <TreeItemContent>
                                    <Divider color='secondary' />
                                </TreeItemContent>
                            </TreeItem>
                        )}
                    </Fragment>
                ))}
            </Tree>
        </nav>
    )
}
