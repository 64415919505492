import { all } from './all'
import { diagnostics } from './diagnostics'
import { machineRelations } from './machineRelations'
import { microwaves } from './microwaves'
import { terminals } from './terminals'

export const machines = [
    ...all,
    ...diagnostics,
    ...machineRelations,
    ...microwaves,
    ...terminals,
]
