import { http, HttpResponse } from 'msw'
import { StatusCodes } from 'http-status-codes'

import { allManagers } from 'app/mocks/data/park/managers'
import { api } from 'app/utils/constants'

export const getManager = http.get(
    `${process.env.REACT_APP_API_ADDRESS}/${api.park.managers.DETAILS}`,
    ({ params }) => {
        const { id } = params

        const managerFound = allManagers.find(
            (manager) => manager.id.toString() === id,
        )

        // Not found
        if (!managerFound) {
            return new HttpResponse(null, {
                status: StatusCodes.NOT_FOUND,
            })
        }

        return HttpResponse.json(managerFound)
    },
)
