import * as managers from 'app/mocks/data/park/managers/list'

export const supplier1 = {
    id: 1,
    name: 'BioCop',
    client: managers.manager1,
    address: '28 rue de Paradis',
    postal_code: 93000,
    city: 'Saint-Denis',
    phone: '0563259685',
    email: 'fournisseur@biocop.com',
    opening_hours: '09:30:00',
    closing_hours: '19:00:00',
}

export const supplier2 = {
    id: 2,
    name: 'TexasFood',
    client: managers.manager1,
    address: '15 Rue Montgomerry',
    postal_code: '75100',
    city: 'Paris',
    phone: '0125362589',
    email: 'fournisseur@texas-food.com',
    opening_hours: '09:00:00',
    closing_hours: '19:00:00',
}
