export const urls = {
    ACCOUNT: '/account',
    DASHBOARD: '/dashboard',
    ERROR_404: '/404',
    ERROR_500: '/500',

    auth: {
        IMPERSONATE: '/auth/impersonate',
        LOGIN: '/auth/login',
        RESET_PASSWORD: '/password-reset',
    },

    catalogue: {
        DISCOUNTS: '/catalogue/discounts',
        INDEX: '/catalogue',
        PRICE_TABLES: '/catalogue/price-tables',
        PRODUCTS: '/catalogue/products',
        STATS: '/catalogue/stats',

        suppliers: {
            CREATE: '/catalogue/suppliers/create',
            DETAILS: '/catalogue/suppliers/:id',
            LIST: '/catalogue/suppliers',
        },
    },

    machines: {
        ALL: '/machines/all',
        INDEX: '/machines',

        microwaves: {
            CREATE: '/machines/microwaves/create',
            DETAILS: '/machines/microwaves/:id',
            LIST: '/machines/microwaves',
            SETTINGS: '/machines/microwaves/:id/settings',
        },

        planograms: {
            CREATE: '/machines/planograms/create/:id',
            DETAILS: '/machines/planograms/:id',
            LIST: '/machines/planograms',
            PRODUCT_INFO: '/machines/planograms/:id/product-info',
            TECHNICAL_DATA: '/machines/planograms/:id/technical-data',
        },
    },

    operations: {
        DELIVERIES: '/operations/deliveries',
        DESTOCKING: '/operations/destocking',
        ENCODED_PRODUCTS: '/operations/encoded-products',
        INCIDENTS: '/operations/incidents',
        INDEX: '/operations',
        INTERVENTIONS: '/operations/interventions',
        INCIDENTS_RABBITMQ: '/operations/rabbitmq-incidents',
    },

    park: {
        INDEX: '/park',

        clients: {
            CREATE: '/park/clients/create',
            DETAILS: '/park/clients/:id',
            LIST: '/park/clients',
        },

        managers: {
            CREATE: '/park/managers/create',
            DETAILS: '/park/managers/:id',
            LIST: '/park/managers',
        },

        sites: {
            INDEX: '/park/sites',
            DETAILS: '/park/sites/:id',
            MACHINE_MARKETING:
                '/park/sites/:idSite/machine-marketing/:idMachine',
            MACHINE_STOCK: '/park/sites/:idSite/machine-stock/:idMachine',
            TECHNICAL_DATA:
                '/park/sites/:idSite/machine-technical-data/:idMachine',
        },
    },

    reports: {
        INDEX: '/reports',
        SUPPLY: '/reports/supply',

        service: {
            CREATE: '/reports/service/create',
            DETAILS: '/reports/service/:id',
            LIST: '/reports/service',
        },
    },

    settings: {
        CATEGORIES: '/settings/categories',
        EMAIL: '/settings/email',
        INDEX: '/settings',
        THEME: '/settings/theme',
        TICKET: '/settings/ticket',
    },

    transactions: {
        BLOCKED_CARDS: '/transactions/blocked-cards',
        INDEX: '/transactions',
        REFILLS: '/transactions/refills',
        REFUNDS: '/transactions/refunds',
        SALES: '/transactions/sales',
    },

    users: {
        INDEX: '/users',
        KONVIVES: '/users/konvives',

        deliveryMen: {
            CREATE: '/users/delivery-men/create',
            DETAILS: '/users/delivery-men/:id',
            LIST: '/users/delivery-men',
        },

        konsole: {
            CREATE: '/users/konsole/create',
            DETAILS: '/users/konsole/:id',
            LIST: '/users/konsole',
        },

        profiles: {
            CREATE: '/users/profiles/create',
            DETAILS: '/users/profiles/:id',
            LIST: '/users/profiles',
        },

        services: {
            CREATE: '/users/services/create',
            DETAILS: '/users/services/:id',
            LIST: '/users/services',
        },

        technicians: {
            CREATE: '/users/technicians/create',
            DETAILS: '/users/technicians/:id',
            LIST: '/users/technicians',
        },
    },
}
