import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Button, makeStyles, Menu, MenuItem } from '@material-ui/core'
import oldCookies from 'js-cookie'

import { Flex } from 'app/ui'

import { languages } from 'app/utils/app/language'
import { assets } from 'app/utils/constants'
import { joinStyles, palette, various } from 'app/utils/theme'
import { Locale } from 'app/types/shared/language'

const useStyles = makeStyles((theme) => ({
    button: {
        height: '45px',
        margin: `0 ${various.padding}`,
        color: palette.secondary.main,
        border: 'unset',
        borderRadius: various.borderRadius,
    },

    buttonLogin: {
        color: palette.primary.main,
        border: `1px solid ${palette.primary.main}`,
    },

    name: {
        display: 'none',
        paddingLeft: '10px',
        [theme.breakpoints.up('md')]: {
            display: 'unset',
        },
    },

    nameLogin: {
        display: 'unset',
    },

    flagBox: {
        width: '50px',
        paddingRight: '5px',
    },

    flag: {
        height: '20px',
        maxWidth: '100%',
    },
}))

export const ChooseLanguage = ({ isLogin = false }) => {
    const classes = useStyles()
    const { i18n } = useTranslation()

    const [anchorEl, setAnchorEl] = useState(null)

    const isOpen = Boolean(anchorEl)

    /** Open the language selection. */
    const handleClick = (event) => {
        setAnchorEl(event.currentTarget)
    }

    /** Close the language selection. */
    const handleClose = () => {
        setAnchorEl(null)
    }

    /** Change the language for the new selected. */
    const handleChangeLanguage = (locale) => {
        i18n.changeLanguage(locale)
        oldCookies.set('i18next', locale)
    }

    return (
        <>
            <Button
                className={joinStyles(
                    classes.button,
                    isLogin && classes.buttonLogin,
                )}
                variant='outlined'
                onClick={handleClick}
            >
                <img
                    className={classes.flag}
                    src={assets.flags[i18n.language]}
                    alt={i18n.language}
                />

                <div
                    className={joinStyles(
                        classes.name,
                        isLogin && classes.nameLogin,
                    )}
                >
                    {languages[i18n.language]}
                </div>
            </Button>

            <Menu
                anchorEl={anchorEl}
                open={isOpen}
                onClose={handleClose}
            >
                {Locale.options.map((locale) => (
                    <MenuItem
                        key={locale}
                        onClick={() => {
                            handleClose()
                            handleChangeLanguage(locale)
                        }}
                    >
                        <Flex className={classes.flagBox}>
                            <img
                                className={classes.flag}
                                src={assets.flags[locale]}
                                alt={locale.name}
                            />
                        </Flex>

                        {languages[locale]}
                    </MenuItem>
                ))}
            </Menu>
        </>
    )
}
