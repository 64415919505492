import { useTranslation } from 'react-i18next'
import { makeStyles } from '@material-ui/core'

import { joinStyles } from 'app/utils/theme'

const useStyles = makeStyles({
    root: {
        width: '50px',
        height: '50px',
        margin: '10px',
        clipPath: 'circle()',
        backgroundColor: 'hsla(0, 0%, 0%, 0.2)',
    },

    image: {
        width: '100%',
        height: '100%',
        objectFit: 'cover',
        clipPath: 'circle()',
    },
})

export const Avatar = ({ className, src, ...properties }) => {
    const classes = useStyles()
    const { t } = useTranslation()

    return (
        <div className={joinStyles(classes.root, className)}>
            <img
                className={classes.image}
                src={src}
                alt={t('avatar')}
                {...properties}
            />
        </div>
    )
}
