import { ProgressBar, Label } from 'react-aria-components'
import { makeStyles } from '@material-ui/core'
import { joinStyles, palette } from 'app/utils/theme'

const useStyles = makeStyles({
    '@keyframes indeterminate': {
        from: {
            transform: 'rotate(0deg)',
        },
        to: {
            transform: 'rotate(360deg)',
        },
    },

    root: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        '&:not([aria-valuenow]) $circle': {
            stroke: 'unset',
        },

        '&:not([aria-valuenow]) > svg': {
            animation: '$indeterminate 1s linear infinite',
        },
    },

    circle: {
        stroke: palette.primary.dark,
    },

    progress: {
        stroke: palette.primary.main,
    },

    percentage: {
        fill: palette.text.primary,
        fontSize: '6px',
        fontWeight: 'bold',
        textAnchor: 'middle',
        dominantBaseline: 'central',
    },

    text: {
        scrollMarginTop: '10px',
    },
})

export const CircularProgress = ({ className, label, ...properties }) => {
    const classes = useStyles()

    const center = 16
    const strokeWidth = 3
    const radius = 16 - strokeWidth
    const circumference = 2 * Math.PI * radius

    return (
        <ProgressBar {...properties}>
            {({ percentage, valueText }) => {
                return (
                    <div
                        className={joinStyles(classes.root, className)}
                        aria-valuenow={properties.value}
                    >
                        <svg
                            width={64}
                            height={64}
                            viewBox='0 0 32 32'
                            fill='none'
                            strokeWidth={strokeWidth}
                        >
                            {/* Internal circle */}
                            <circle
                                className={classes.circle}
                                cx={center}
                                cy={center}
                                r={radius - (strokeWidth / 2 - 0.25)}
                                strokeWidth={0.5}
                            />

                            {/* External circle */}
                            <circle
                                className={classes.circle}
                                cx={center}
                                cy={center}
                                r={radius + (strokeWidth / 2 - 0.25)}
                                strokeWidth={0.5}
                            />

                            {/* Progress */}
                            <circle
                                className={classes.progress}
                                cx={center}
                                cy={center}
                                r={radius}
                                strokeDasharray={`${circumference} ${circumference}`}
                                strokeDashoffset={
                                    circumference -
                                    ((percentage ?? 60) / 100) * circumference
                                }
                                strokeLinecap='round'
                                transform='rotate(-90 16 16)'
                            />

                            {/* Text */}
                            {!!percentage && (
                                <text
                                    className={classes.percentage}
                                    x={center}
                                    y={center}
                                >
                                    {valueText}
                                </text>
                            )}
                        </svg>

                        <Label className={classes.text}>{label}</Label>
                    </div>
                )
            }}
        </ProgressBar>
    )
}
