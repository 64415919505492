export const api = {
    EMAIL_PARAMETERS: 'email_parameters/',
    PRODUCTS_IMPORT: 'import/products/',
    STATS_STOCK: 'stats_stock',
    TICKET_PARAMETERS: 'ticket_parameters/',
    UPLOAD_SIGNATURE: 'images_sign_upload/',

    auth: {
        ME: 'me',
        LOGIN: 'token/',
        REFRESH_TOKENS: 'token/refresh/',
        ASK_RESET_PASSWORD: 'password_reset/',
    },

    catalogue: {
        SUPPLIERS: 'supplier_products/',

        products: {
            LIST: 'products/',

            allergens: {
                DETAILS: 'allergens/:id/',
                LIST: 'allergens/',
                PRIORITIES: 'catalogue/products/allergens/priorities/',
            },

            categories: {
                DETAILS: 'categories_products/:id/',
                LIST: 'categories_products/',
                PRIORITIES: 'catalogue/products/categories/priorities/',
            },

            subcategories: {
                DETAILS: 'sub_categories_products/:id/',
                LIST: 'sub_categories_products/',
                PRIORITIES: 'catalogue/products/subcategories/priorities/',
            },
        },
    },

    machines: {
        ALL: 'machines/',
        PLANOGRAMS: 'machines_grid_items/',

        microwaves: {
            DETAILS: 'machines/microwaves/:id',
            LIST: 'machines/microwaves',
        },

        terminals: {
            REBOOT: 'machines/terminals/:id/reboot',
        },
    },

    park: {
        CLIENTS: 'site_customer/',
        DIAGNOSTIC: 'diagnostic/',
        MACHINE_RELATIONS: 'machines_grid/',
        PLANOGRAMS: 'machines_grid_items/',

        managers: {
            DETAILS: 'clients/:id/',
            LIST: 'clients/',

            categories: {
                LIST: 'client_category',
            },
        },

        sites: {
            DETAILS: 'sites/:id/',
            LIST: 'sites/',

            types: {
                DETAILS: 'site_types/:id/',
                LIST: 'site_types/',
                PRIORITIES: 'park/sites/types/priorities',
            },

            zones: {
                DETAILS: 'zones/:id/',
                LIST: 'zones/',
                PRIORITIES: 'park/sites/zones/priorities',
            },
        },
    },

    reports: {
        services: {
            ALL: 'reports/services/',
            DETAILS: 'reports/services/:id/',
        },
    },

    transactions: {
        BLOCKED_CARDS: 'blocked_cards/',
        REFILLS: 'credit_refill/',
        SALES_OLD: 'sales/',
        SALES: 'transactions/sales/',
    },

    users: {
        KONSOLE: 'users/',
        KONSOLE_IMPERSONATE: 'users/impersonate/',
        PERMISSIONS: 'users_permissions/',
        SERVICES: 'users/services/',

        deliveryMen: {
            DETAILS: 'deliverymen/:id/',
            LIST: 'deliverymen/',
        },

        konvives: {
            CREATION_LINK: 'users/konvives/creation-link',
            IMPORT: 'machines_users/import/',
        },

        profiles: {
            DETAILS: 'users_groups/:id/',
            LIST: 'users_groups/',
        },

        technicians: {
            DETAILS: 'technicians/:id/',
            LIST: 'technicians/',
        },
    },
}
