import ReactDOM from 'react-dom'
import { BrowserRouter } from 'react-router-dom'

import App from './app/App'
import { enableMocks } from 'app/mocks'
import './styles.css'

function Layout() {
    return (
        <BrowserRouter>
            <App />
        </BrowserRouter>
    )
}

const rootElement = document.getElementById('root')

enableMocks().then(() => {
    ReactDOM.render(<Layout />, rootElement)
})
