import konsoleColor from 'assets/ethik/konsole_color.svg'
import konsoleWhite from 'assets/ethik/konsole_white.svg'

import france from 'assets/flags/france.svg'
import spain from 'assets/flags/spain.svg'
import unitedKingdom from 'assets/flags/united_kingdom.svg'

export const assets = {
    ethik: {
        KONSOLE_COLOR: konsoleColor,
        KONSOLE_WHITE: konsoleWhite,
    },

    flags: {
        'en-GB': unitedKingdom,
        'es-ES': spain,
        'fr-FR': france,
    },
}
