import { Tab, TabList, TabPanel, Tabs } from 'react-aria-components'
import { makeStyles } from '@material-ui/core'

import { joinStyles, palette, themeMultiply, various } from 'app/utils/theme'

const useStyles = makeStyles({
    root: {
        margin: various.margin,
        width: '100%',
    },

    tabsList: {
        display: 'flex',
        justifyContent: 'center',
        margin: 'auto',
        padding: '3px',
        backgroundColor: `hsla(${palette.primaryRaw.main}, 0.2)`,
        borderRadius: various.borderRadius,
        '& [data-hovered]': {
            backgroundColor: `hsla(${palette.secondaryRaw.main}, 0.5)`,
        },
        '& [data-selected]': {
            color: palette.secondary.main,
            backgroundColor: palette.primary.main,
            boxShadow: various.boxShadow.component,
        },
    },

    tab: {
        cursor: 'pointer',
        width: '100%',
        margin: '5px',
        padding: `5px ${various.padding}`,
        fontSize: '1.5rem',
        fontWeight: 'bold',
        textAlign: 'center',
        borderRadius: various.borderRadius,
    },

    tabPanel: {
        width: '100%',
        padding: `${themeMultiply(various.padding, 2)} 0`,
    },
})

export const TabsNew = ({
    className,
    tabs,
    tabListClassName,
    ...properties
}) => {
    const classes = useStyles()

    return (
        <Tabs
            className={joinStyles(classes.root, className)}
            {...properties}
        >
            {/* Choices */}
            <TabList className={joinStyles(classes.tabsList, tabListClassName)}>
                {tabs.map((tab, index) => (
                    <Tab
                        key={index}
                        id={tab.id}
                        className={classes.tab}
                    >
                        {tab.name}
                    </Tab>
                ))}
            </TabList>

            {/* Content */}
            {tabs.map((tab, index) => (
                <TabPanel
                    key={index}
                    id={tab.id}
                    className={classes.tabPanel}
                >
                    {tab.content}
                </TabPanel>
            ))}
        </Tabs>
    )
}
