import { useEffect, useState } from 'react'
import { makeStyles, useTheme } from '@material-ui/core'
import { createId } from '@paralleldrive/cuid2'

import { joinStyles } from 'app/utils/theme'

const useStyles = makeStyles((theme) => ({
    root: {
        zIndex: theme.zIndex.modal, // 100 in refactorisation
        position: 'fixed',
        top: 0,
        left: 0,
        alignItems: 'center',
        justifyContent: 'center',
        width: '100vw',
        height: '100vh',
        margin: 0,
        padding: 0,
        border: 0,
        backgroundColor: 'hsla(0, 0%, 0%, 0.6)',
    },

    flex: {
        display: 'flex',
    },
}))

export const Dialog = ({
    className,
    open,
    onClose,
    disableBackdropClick = false,
    ...properties
}) => {
    const theme = useTheme()
    const classes = useStyles(theme)

    const [id, setId] = useState('')

    /** Close the dialog if clicked outside. */
    const handleClick = (event) => {
        if (disableBackdropClick) return

        const dialog = document.querySelector(`#${id}`)

        if (event.target === dialog && onClose) onClose()
    }

    // Set the ID
    useEffect(() => setId(createId()), [])

    // Show the dialog
    useEffect(() => {
        const showDialog = () => {
            const dialog = document.querySelector(`#${id}`)

            if (dialog) dialog.show()
        }

        if (!!id && open) showDialog()
    }, [id, open])

    // Close the dialog
    useEffect(() => {
        const closeDialog = () => {
            const dialog = document.querySelector(`#${id}`)

            if (dialog) dialog.close()
        }

        if (!!id && !open) closeDialog()
    }, [id, open])

    return (
        <>
            {/* eslint-disable-next-line jsx-a11y/click-events-have-key-events, jsx-a11y/no-noninteractive-element-interactions */}
            <dialog
                id={id}
                className={joinStyles(
                    classes.root,
                    open && classes.flex,
                    className,
                )}
                onClick={handleClick}
                {...properties}
            />
        </>
    )
}
