import { http, HttpResponse } from 'msw'

import { api, isNumeric } from 'app/utils/constants'
import { allServiceReports } from 'app/mocks/data/reports/services'

export const getAllServiceReports = http.get(
    `${process.env.REACT_APP_API_ADDRESS}/${api.reports.services.ALL}`,
    ({ request }) => {
        const url = new URL(request.url)
        const limit = url.searchParams.get('limit')
        const offset = url.searchParams.get('offset')

        // Pagination
        const limitArray =
            allServiceReports.length > limit
                ? offset + limit
                : allServiceReports.length

        const results =
            isNumeric(offset) && isNumeric(limit)
                ? allServiceReports.slice(offset, limitArray)
                : allServiceReports

        const next =
            isNumeric(offset) &&
            isNumeric(limit) &&
            offset + results.length < allServiceReports.length
                ? 'again'
                : undefined

        return HttpResponse.json({
            count: allServiceReports.length,
            next,
            results,
        })
    },
)
