import { http, HttpResponse } from 'msw'
import { StatusCodes } from 'http-status-codes'

import { api } from 'app/utils/constants'
import { allServiceReports } from 'app/mocks/data/reports/services'
import { StatusReport } from 'app/types/reports'

export const getServiceReport = http.get(
    `${process.env.REACT_APP_API_ADDRESS}/${api.reports.services.DETAILS}`,
    ({ params }) => {
        const { id } = params

        const serviceReportFound = allServiceReports.find(
            (service) => service.id.toString() === id,
        )

        // Not found
        if (!serviceReportFound) {
            return new HttpResponse(null, {
                status: StatusCodes.NOT_FOUND,
            })
        }

        // Not generated
        if (serviceReportFound.status === StatusReport.enum.IN_PROGRESS) {
            return new HttpResponse(null, {
                status: StatusCodes.CONFLICT,
            })
        }

        return HttpResponse.json(serviceReportFound)
    },
)
