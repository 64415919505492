import * as lockersDryStandard from 'app/mocks/data/machines/all/standard/lockersDry'

export const lockerDryDiagnostic1 = {
    machine: {
        id: lockersDryStandard.lockerDryStandard1.id,
        type: lockersDryStandard.lockerDryStandard1.category,
    },
    internet: {
        is_online: true,
        last_loss_connection: '2022-12-27 08:26:49.219717',
        last_heartbeat_connection: '2022-12-27 08:26:49.219717',
    },
}

export const lockerDryDiagnostic2 = {
    machine: {
        id: lockersDryStandard.lockerDryStandard2.id,
        type: lockersDryStandard.lockerDryStandard2.category,
    },
    internet: {
        is_online: true,
        last_loss_connection: '2022-12-27 08:26:49.219717',
        last_heartbeat_connection: '2022-12-27 08:26:49.219717',
    },
}

export const lockerDryDiagnostic3 = {
    machine: {
        id: lockersDryStandard.lockerDryStandard3.id,
        type: lockersDryStandard.lockerDryStandard3.category,
    },
    internet: {
        is_online: true,
        last_loss_connection: '2022-12-27 08:26:49.219717',
        last_heartbeat_connection: '2022-12-27 08:26:49.219717',
    },
}
