import * as clients from 'app/mocks/data/park/clients/list'

export const service1 = {
    id: 1,
    name: 'Service 1',
    client: clients.client1,
    domain_name: 'eat.com',
}

export const service2 = {
    id: 2,
    name: 'Service 2',
    client: clients.client1,
}

export const service3 = {
    id: 3,
    name: 'Service 3',
    client: clients.client1,
}

export const service4 = {
    id: 4,
    name: 'Service 4',
    client: clients.client1,
    domain_name: 'product.com',
}

export const service5 = {
    id: 5,
    name: 'Service 5',
    client: clients.client1,
}

export const service6 = {
    id: 6,
    name: 'Service 6',
    client: clients.client2,
}

export const service7 = {
    id: 7,
    name: 'Service 7',
    client: clients.client15,
}

export const service8 = {
    id: 8,
    name: 'Service 8',
    client: clients.client8,
}

export const service9 = {
    id: 9,
    name: 'Service 9',
    client: clients.client11,
}

export const service10 = {
    id: 10,
    name: 'Service 10',
    client: clients.client19,
}

export const service11 = {
    id: 11,
    name: 'Service 11',
    client: clients.client5,
}

export const service12 = {
    id: 12,
    name: 'Service 12',
    client: clients.client9,
}

export const service13 = {
    id: 13,
    name: 'Service 13',
    client: clients.client14,
}

export const service14 = {
    id: 14,
    name: 'Service 14',
    client: clients.client3,
}

export const service15 = {
    id: 15,
    name: 'Service 15',
    client: clients.client7,
}

export const service16 = {
    id: 16,
    name: 'Service 16',
    client: clients.client21,
}

export const service17 = {
    id: 17,
    name: 'Service 17',
    client: clients.client6,
}

export const service18 = {
    id: 18,
    name: 'Service 18',
    client: clients.client12,
}

export const service19 = {
    id: 19,
    name: 'Service 19',
    client: clients.client10,
}

export const service20 = {
    id: 20,
    name: 'Service 20',
    client: clients.client16,
}

export const service21 = {
    id: 21,
    name: 'Service 21',
    client: clients.client4,
}

export const service22 = {
    id: 22,
    name: 'Service 22',
    client: clients.client18,
}

export const service23 = {
    id: 23,
    name: 'Service 23',
    client: clients.client1,
}

export const service24 = {
    id: 24,
    name: 'Service 24',
    client: clients.client20,
}

export const service25 = {
    id: 25,
    name: 'Service 25',
    client: clients.client13,
}

export const service26 = {
    id: 26,
    name: 'Service 26',
    client: clients.client17,
}

export const service27 = {
    id: 27,
    name: 'Service 27',
    client: clients.client18,
}

export const service28 = {
    id: 28,
    name: 'Service 28',
    client: clients.client2,
}

export const service29 = {
    id: 29,
    name: 'Service 29',
    client: clients.client4,
}

export const service30 = {
    id: 30,
    name: 'Service 30',
    client: clients.client8,
}
