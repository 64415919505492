import { http, HttpResponse } from 'msw'

import { api, isNumeric } from 'app/utils/constants'
import { allMachines } from 'app/mocks/data/machines/all'

export const getAllMachines = http.get(
    `${process.env.REACT_APP_API_ADDRESS}/${api.machines.ALL}`,
    ({ request }) => {
        const url = new URL(request.url)
        const limit = url.searchParams.get('limit')
        const offset = url.searchParams.get('offset')
        const managerIdsFilterSimple = url.searchParams.get('site__client')
        const managerIdsFilter = url.searchParams.get('site__client__in')
        const clientIdsFilterSimple = url.searchParams.get(
            'site__customer_name',
        )
        const clientIdsFilter = url.searchParams.get('site__customer_name__in')
        const siteIdsFilterSimple = url.searchParams.get('site')
        const siteIdsFilter = url.searchParams.get('site__in')
        const nameFilter = url.searchParams.get('name__icontains')

        let allMachinesFiltered = allMachines

        // Manager filter simple (to delete when refactoring the page)
        if (!!managerIdsFilterSimple) {
            const managerIdsSplit = managerIdsFilterSimple.split(',')

            allMachinesFiltered = allMachinesFiltered.filter((machine) =>
                managerIdsSplit.includes(machine.site.client.toString()),
            )
        }

        // Manager filter
        if (!!managerIdsFilter) {
            const managerIdsSplit = managerIdsFilter.split(',')

            allMachinesFiltered = allMachinesFiltered.filter((machine) =>
                managerIdsSplit.includes(machine.site.client.toString()),
            )
        }

        // Client filter simple (to delete when refactoring the page)
        if (!!clientIdsFilterSimple) {
            const clientIdsSplit = clientIdsFilterSimple.split(',')

            allMachinesFiltered = allMachinesFiltered.filter((machine) =>
                clientIdsSplit.includes(
                    machine.site.customer_name.id.toString(),
                ),
            )
        }

        // Client filter
        if (!!clientIdsFilter) {
            const clientIdsSplit = clientIdsFilter.split(',')

            allMachinesFiltered = allMachinesFiltered.filter((machine) =>
                clientIdsSplit.includes(
                    machine.site.customer_name.id.toString(),
                ),
            )
        }

        // Site filter simple (to delete when refactoring the page)
        if (!!siteIdsFilterSimple) {
            const siteIdsSplit = siteIdsFilterSimple.split(',')

            allMachinesFiltered = allMachinesFiltered.filter((machine) =>
                siteIdsSplit.includes(machine.site.id.toString()),
            )
        }

        // Site filter
        if (!!siteIdsFilter) {
            const siteIdsSplit = siteIdsFilter.split(',')

            allMachinesFiltered = allMachinesFiltered.filter((machine) =>
                siteIdsSplit.includes(machine.site.id.toString()),
            )
        }

        // Name filter
        if (!!nameFilter) {
            allMachinesFiltered = allMachinesFiltered.filter((machine) =>
                machine.name.toLowerCase().includes(nameFilter),
            )
        }

        // Pagination
        const limitArray =
            allMachinesFiltered.length > limit
                ? offset + limit
                : allMachinesFiltered.length

        const results =
            isNumeric(offset) && isNumeric(limit)
                ? allMachinesFiltered.slice(offset, limitArray)
                : allMachinesFiltered

        const next =
            isNumeric(offset) &&
            isNumeric(limit) &&
            offset + results.length < allMachinesFiltered.length
                ? 'again'
                : undefined

        return HttpResponse.json({
            count: allMachinesFiltered.length,
            next,
            results,
        })
    },
)
