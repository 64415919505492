/** Delay (in milliseconds) to debounce when typing a text search. */
export const DELAY_DEBOUNCE = 800

/** Size maximum (2 Mo) of the image file. */
export const MAX_IMAGE_SIZE = 1_024 * 1_024 * 2

/** Length maximum of the data in the request. */
export const PAGINATION_LIMIT = 40

/** Size limit to load more data. */
export const PAGINATION_BOTTOM = 150

/** MIME types of files. */
export const mimeTypes = {
    AVIF: 'image/avif',
    CSV: 'text/csv',
    EXCEL: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
    JPEG: 'image/jpeg',
    PDF: 'application/pdf',
    PNG: 'image/png',
    SVG: 'image/svg',
    WEBP: 'image/webp',
}

export const links = {
    KONVIVES: 'https://konvives.com',
    TICKETING: 'https://e-thik.odoo.com',
}

/** Make a pause with the time given (in milliseconds). */
export const pause = async (time) => {
    await new Promise((resolve) => setTimeout(resolve, time))
}

/** Know if the value passed is a number or not. */
export const isNumeric = (numberToTest) =>
    (typeof numberToTest === 'number' ||
        (typeof numberToTest === 'string' && numberToTest.trim() !== '')) &&
    !isNaN(numberToTest)

/** Konsole users roles availables. */
export const roles = {
    DELIVERY_MEN: 'Approvisionneur',
    MANAGER: 'Gestionnaire',
    TECHNICIAN: 'Technicien',
}

/** Transform the query into an array of number. */
export const queryIntoArrayNumber = (query) =>
    query
        .split(',')
        .map(Number)
        .filter((value) => !Number.isNaN(value))
