import { z } from 'zod'
import { Client } from 'app/types/shared/park/client'

/** Service related to a konvive user to get an employer contribution. */
export const Service = z.object({
    /** ID of the service. */
    id: z.number().positive(),

    /** Name of the service. */
    name: z.string().min(1),

    /** Client related to the service. */
    client: Client,

    /** The domain name related to the service. */
    domain_name: z.string().min(1),
})
