import { makeStyles } from '@material-ui/core'

import { joinStyles, palette, various } from 'app/utils/theme'

const useStyles = makeStyles({
    base: {
        width: '80%',
        margin: `${various.margin} auto`,
        border: 'none',
        borderTop: '1px solid',
    },

    grey: {
        borderColor: palette.grey[50],
    },

    primary: {
        borderColor: palette.primary.main,
    },

    secondary: {
        borderColor: palette.secondary.main,
    },
})

export const Divider = ({ className, color = 'grey', ...properties }) => {
    const classes = useStyles()

    return (
        <hr
            className={joinStyles(classes.base, classes[color], className)}
            {...properties}
        />
    )
}
