import { testRights } from './rights'
import { manager } from './roles'
import * as managers from 'app/mocks/data/park/managers/list'

export const lambda1 = {
    id: 1,
    username: 'lambda1',
    email: 'lambda1@e-thik.com',
    last_name: 'RENO',
    first_name: 'Jean',
    is_staff: false,
    profil: {
        client: [managers.manager1],
        avatar: 'https://apis-dev.e-thik.com/media/avatars/default.png',
        mail_alarm: true,
        mail_dlc: true,
        mail_offline: true,
        mail_technical_infos: true,
        mail_high_stock: true,
        mail_low_stock: true,
        mail_dispute: true,
    },
    groups: [manager, testRights],
}

export const lambda2 = {
    id: 2,
    username: 'lambda2',
    email: 'lambda2@e-thik.com',
    last_name: 'Catherine',
    first_name: 'Jean',
    is_staff: false,
    profil: {
        client: [managers.manager1],
        avatar: 'https://apis-dev.e-thik.com/media/avatars/default.png',
        mail_alarm: true,
        mail_dlc: true,
        mail_offline: true,
        mail_technical_infos: true,
        mail_high_stock: true,
        mail_low_stock: true,
        mail_dispute: true,
    },
    groups: [manager, testRights],
}

export const lambda3 = {
    id: 3,
    username: 'lambda3',
    email: 'lambda3@e-thik.com',
    last_name: 'Vincent',
    first_name: 'Mazet',
    is_staff: false,
    profil: {
        client: [managers.manager2],
        avatar: 'https://apis-dev.e-thik.com/media/avatars/default.png',
        mail_alarm: true,
        mail_dlc: true,
        mail_offline: true,
        mail_technical_infos: true,
        mail_high_stock: true,
        mail_low_stock: true,
        mail_dispute: true,
    },
    groups: [manager, testRights],
}

export const lambda4 = {
    id: 4,
    username: 'lambda4',
    email: 'lambda4@e-thik.com',
    last_name: 'Martin',
    first_name: 'Dupont',
    is_staff: false,
    profil: {
        client: [managers.manager5, managers.manager12],
        avatar: 'https://apis-dev.e-thik.com/media/avatars/default.png',
        mail_alarm: false,
        mail_dlc: true,
        mail_offline: false,
        mail_technical_infos: true,
        mail_high_stock: false,
        mail_low_stock: true,
        mail_dispute: true,
    },
    groups: [manager, testRights],
}

export const lambda5 = {
    id: 5,
    username: 'lambda5',
    email: 'lambda5@e-thik.com',
    last_name: 'Durand',
    first_name: 'Alice',
    is_staff: false,
    profil: {
        client: [managers.manager7, managers.manager15],
        avatar: 'https://apis-dev.e-thik.com/media/avatars/default.png',
        mail_alarm: true,
        mail_dlc: false,
        mail_offline: true,
        mail_technical_infos: false,
        mail_high_stock: true,
        mail_low_stock: false,
        mail_dispute: true,
    },
    groups: [manager, testRights],
}

export const lambda6 = {
    id: 6,
    username: 'lambda6',
    email: 'lambda6@e-thik.com',
    last_name: 'Petit',
    first_name: 'Laura',
    is_staff: false,
    profil: {
        client: [managers.manager9, managers.manager18],
        avatar: 'https://apis-dev.e-thik.com/media/avatars/default.png',
        mail_alarm: true,
        mail_dlc: true,
        mail_offline: false,
        mail_technical_infos: false,
        mail_high_stock: true,
        mail_low_stock: true,
        mail_dispute: false,
    },
    groups: [manager, testRights],
}

export const lambda7 = {
    id: 7,
    username: 'lambda7',
    email: 'lambda7@e-thik.com',
    last_name: 'Bernard',
    first_name: 'Paul',
    is_staff: false,
    profil: {
        client: [managers.manager2, managers.manager20],
        avatar: 'https://apis-dev.e-thik.com/media/avatars/default.png',
        mail_alarm: false,
        mail_dlc: true,
        mail_offline: true,
        mail_technical_infos: true,
        mail_high_stock: false,
        mail_low_stock: false,
        mail_dispute: true,
    },
    groups: [manager, testRights],
}

export const lambda8 = {
    id: 8,
    username: 'lambda8',
    email: 'lambda8@e-thik.com',
    last_name: 'Giraud',
    first_name: 'Sophie',
    is_staff: false,
    profil: {
        client: [managers.manager14, managers.manager22],
        avatar: 'https://apis-dev.e-thik.com/media/avatars/default.png',
        mail_alarm: true,
        mail_dlc: false,
        mail_offline: false,
        mail_technical_infos: true,
        mail_high_stock: true,
        mail_low_stock: false,
        mail_dispute: false,
    },
    groups: [manager, testRights],
}
