import { permissions } from 'app/utils/constants'

const park = [
    // Managers
    { id: 45, codename: permissions.park.managers.CREATE },
    { id: 48, codename: permissions.park.managers.READ },
    { id: 46, codename: permissions.park.managers.UPDATE },
    { id: 47, codename: permissions.park.managers.DELETE },

    // Clients
    { id: 257, codename: permissions.park.clients.CREATE },
    { id: 260, codename: permissions.park.clients.READ },
    { id: 258, codename: permissions.park.clients.UPDATE },
    { id: 259, codename: permissions.park.clients.DELETE },

    // Sites
    { id: 93, codename: permissions.park.sites.CREATE },
    { id: 96, codename: permissions.park.sites.READ },
    { id: 94, codename: permissions.park.sites.UPDATE },
    { id: 95, codename: permissions.park.sites.DELETE },
]

const machines = [
    // All machines
    { id: 25, codename: permissions.machines.all.CREATE },
    { id: 28, codename: permissions.machines.all.READ },
    { id: 26, codename: permissions.machines.all.UPDATE },
    { id: 27, codename: permissions.machines.all.DELETE },

    // Machine relation
    { id: 301, codename: permissions.machines.machineRelations.CREATE },
    { id: 304, codename: permissions.machines.machineRelations.READ },
    { id: 302, codename: permissions.machines.machineRelations.UPDATE },
    { id: 303, codename: permissions.machines.machineRelations.DELETE },

    // Marketing images
    { id: 57, codename: permissions.machines.marketingImages.CREATE },
    { id: 60, codename: permissions.machines.marketingImages.READ },
    { id: 58, codename: permissions.machines.marketingImages.UPDATE },
    { id: 59, codename: permissions.machines.marketingImages.DELETE },

    // Marketing videos
    { id: 73, codename: permissions.machines.marketingVideos.CREATE },
    { id: 76, codename: permissions.machines.marketingVideos.READ },
    { id: 74, codename: permissions.machines.marketingVideos.UPDATE },
    { id: 75, codename: permissions.machines.marketingVideos.DELETE },

    // Technical data
    { id: 29, codename: permissions.machines.technicalData.CREATE },
    { id: 32, codename: permissions.machines.technicalData.READ },
    { id: 30, codename: permissions.machines.technicalData.UPDATE },
    { id: 31, codename: permissions.machines.technicalData.DELETE },

    // Planograms
    { id: 305, codename: permissions.machines.planograms.CREATE },
    { id: 308, codename: permissions.machines.planograms.READ },
    { id: 306, codename: permissions.machines.planograms.UPDATE },
    { id: 307, codename: permissions.machines.planograms.DELETE },
]

const catalogue = [
    // Suppliers
    { id: 137, codename: permissions.catalogue.suppliers.CREATE },
    { id: 140, codename: permissions.catalogue.suppliers.READ },
    { id: 138, codename: permissions.catalogue.suppliers.UPDATE },
    { id: 139, codename: permissions.catalogue.suppliers.DELETE },

    // Products
    { id: 49, codename: permissions.catalogue.products.CREATE },
    { id: 52, codename: permissions.catalogue.products.READ },
    { id: 50, codename: permissions.catalogue.products.UPDATE },
    { id: 51, codename: permissions.catalogue.products.DELETE },

    // Price line
    { id: 141, codename: permissions.catalogue.priceLine.CREATE },
    { id: 144, codename: permissions.catalogue.priceLine.READ },
    { id: 142, codename: permissions.catalogue.priceLine.UPDATE },
    { id: 143, codename: permissions.catalogue.priceLine.DELETE },

    // Price table
    { id: 145, codename: permissions.catalogue.priceTable.CREATE },
    { id: 148, codename: permissions.catalogue.priceTable.READ },
    { id: 146, codename: permissions.catalogue.priceTable.UPDATE },
    { id: 147, codename: permissions.catalogue.priceTable.DELETE },
]

const transactions = [
    // Sales
    { id: 112, codename: permissions.transactions.sales.READ },

    // Disputes
    { id: 164, codename: permissions.transactions.disputes.READ },

    // Disputed products
    {
        id: 160,
        codename: permissions.transactions.disputedProducts.READ,
    },

    // Refunds
    { id: 288, codename: permissions.transactions.refunds.READ },

    // Refills
    { id: 280, codename: permissions.transactions.refills.READ },
]

const operations = [
    // Deliveries
    { id: 192, codename: permissions.operations.deliveries.READ },

    // Interventions
    {
        id: 212,
        codename: permissions.operations.interventions.READ,
    },

    // Incidents
    { id: 216, codename: permissions.operations.incidents.READ },

    // Destocking
    { id: 172, codename: permissions.operations.destocking.READ },
    { id: 171, codename: permissions.operations.destocking.DELETE },

    // Encoded reports
    {
        id: 200,
        codename: permissions.operations.encodedProducts.READ,
    },
]

const users = [
    // Profiles
    { id: 9, codename: permissions.users.profiles.CREATE },
    { id: 12, codename: permissions.users.profiles.READ },
    { id: 10, codename: permissions.users.profiles.UPDATE },
    { id: 11, codename: permissions.users.profiles.DELETE },

    // Konsole users
    { id: 13, codename: permissions.users.konsole.CREATE },
    { id: 16, codename: permissions.users.konsole.READ },
    { id: 14, codename: permissions.users.konsole.UPDATE },
    { id: 15, codename: permissions.users.konsole.DELETE },

    // Technicians
    { id: 201, codename: permissions.users.technicians.CREATE },
    { id: 204, codename: permissions.users.technicians.READ },
    { id: 202, codename: permissions.users.technicians.UPDATE },
    { id: 203, codename: permissions.users.technicians.DELETE },

    // Delivery men
    { id: 41, codename: permissions.users.deliveryMen.CREATE },
    { id: 44, codename: permissions.users.deliveryMen.READ },
    { id: 42, codename: permissions.users.deliveryMen.UPDATE },
    { id: 43, codename: permissions.users.deliveryMen.DELETE },

    // Konvives
    { id: 77, codename: permissions.users.konvives.CREATE },
    { id: 80, codename: permissions.users.konvives.READ },
    { id: 78, codename: permissions.users.konvives.UPDATE },
    { id: 79, codename: permissions.users.konvives.DELETE },
]

const settings = [
    // Theme
    { id: 269, codename: permissions.settings.theme.CREATE },
    { id: 272, codename: permissions.settings.theme.READ },
    { id: 270, codename: permissions.settings.theme.UPDATE },

    // Zones
    { id: 185, codename: permissions.settings.zones.CREATE },
    { id: 188, codename: permissions.settings.zones.READ },
    { id: 186, codename: permissions.settings.zones.UPDATE },
    { id: 187, codename: permissions.settings.zones.DELETE },

    // Site types
    { id: 181, codename: permissions.settings.siteTypes.CREATE },
    { id: 184, codename: permissions.settings.siteTypes.READ },
    { id: 182, codename: permissions.settings.siteTypes.UPDATE },
    { id: 183, codename: permissions.settings.siteTypes.DELETE },

    // Allergens
    { id: 165, codename: permissions.settings.allergens.CREATE },
    { id: 168, codename: permissions.settings.allergens.READ },
    { id: 166, codename: permissions.settings.allergens.UPDATE },
    { id: 167, codename: permissions.settings.allergens.DELETE },

    // Product categories
    { id: 53, codename: permissions.settings.productCategories.CREATE },
    { id: 56, codename: permissions.settings.productCategories.READ },
    { id: 54, codename: permissions.settings.productCategories.UPDATE },
    { id: 55, codename: permissions.settings.productCategories.DELETE },

    // Subcategory products
    {
        id: 177,
        codename: permissions.settings.subcategoryProducts.CREATE,
    },
    {
        id: 179,
        codename: permissions.settings.subcategoryProducts.DELETE,
    },
    {
        id: 180,
        codename: permissions.settings.subcategoryProducts.READ,
    },
    {
        id: 178,
        codename: permissions.settings.subcategoryProducts.UPDATE,
    },

    // Email
    { id: 205, codename: permissions.settings.email.CREATE },
    { id: 208, codename: permissions.settings.email.READ },
    { id: 206, codename: permissions.settings.email.UPDATE },

    // Ticket
    { id: 249, codename: permissions.settings.ticket.CREATE },
    { id: 252, codename: permissions.settings.ticket.READ },
    { id: 250, codename: permissions.settings.ticket.UPDATE },
]

export const permissionsData = [
    ...park,
    ...machines,
    ...catalogue,
    ...transactions,
    ...operations,
    ...users,
    ...settings,
]
