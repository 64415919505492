import { makeStyles } from '@material-ui/core'

import { joinStyles, palette, various } from 'app/utils/theme'

const useStyles = makeStyles({
    root: {
        '&> tr > td:first-child': {
            borderLeft: `1px solid ${palette.primary.main}`,
            borderBottomLeftRadius: various.borderRadius,
        },

        '&> tr > td:last-child': {
            borderRight: `1px solid ${palette.primary.main}`,
            borderBottomRightRadius: various.borderRadius,
        },
    },
})

export const TableFooter = ({ className, ...properties }) => {
    const classes = useStyles()

    return (
        <tfoot
            className={joinStyles(classes.root, className)}
            {...properties}
        />
    )
}
