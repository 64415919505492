import { useEffect, useState } from 'react'
import { makeStyles } from '@material-ui/core'

import { palette, various } from 'app/utils/theme'

const useStyles = makeStyles({
    root: {
        display: 'flex',
        flexWrap: 'wrap',
        margin: various.padding,
        color: palette.text.primary,
        borderRadius: various.borderRadius,
        backgroundColor: palette.background.secondary,
    },
})

export const Tabs = ({ children, className = '', groupName }) => {
    const classes = useStyles()

    const [mounted, setMounted] = useState(false)

    useEffect(() => setMounted(true), [])

    // Give group name to all chlidren tab
    useEffect(() => {
        const tabGroup = document.querySelector(`#${groupName}`)

        tabGroup &&
            tabGroup
                .querySelectorAll(':scope > input')
                .forEach((element) => element.setAttribute('name', groupName))
        /* eslint-disable-next-line react-hooks/exhaustive-deps */
    }, [mounted])

    return (
        <div
            id={groupName}
            className={`${classes.root} ${className}`}
        >
            {children}
        </div>
    )
}
