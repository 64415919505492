import { makeStyles } from '@material-ui/core'

import { joinStyles } from 'app/utils/theme'

const useStyles = makeStyles({
    root: {
        overflow: 'auto',
    },

    table: {
        width: '100%',
        borderSpacing: '0',
    },
})

export const Table = ({ className, ...properties }) => {
    const classes = useStyles()

    return (
        <div className={joinStyles(classes.root, className)}>
            <table
                className={classes.table}
                {...properties}
            />
        </div>
    )
}
