import { z } from 'zod'
import { Manager } from 'app/types/users/manager'

/** Client of a manager. */
export const Client = z.object({
    /** ID of the client. */
    id: z.number().positive(),

    /** Name of the client. */
    name: z.string().min(1),

    /** Client related manager. */
    client: Manager,

    /** Address of the client. */
    address: z.string().min(1),

    /** City of the client address's. */
    city: z.string().min(1),

    /** Postal code of the client address's. */
    postal_code: z.number().positive(),

    /** Email of the client. */
    email: z.string().email(),

    /** Phone number of the client. */
    phone_number: z.string().min(1),
})
