import { http, HttpResponse } from 'msw'
import { api } from 'app/utils/constants'
import { StatusCodes } from 'http-status-codes'
import { newDateFormatted } from 'app/utils/date'
import { StatusReport } from 'app/types/reports'
import { ServicesReportPayload } from 'app/types/reports/service'

export const createServiceReport = http.post(
    `${process.env.REACT_APP_API_ADDRESS}/${api.reports.services.ALL}`,
    async ({ request }) => {
        const payload = await request.json()
        const payloadParsed = ServicesReportPayload.safeParse(payload)

        // Wrong data
        if (!payloadParsed.success) {
            return new HttpResponse(null, {
                status: StatusCodes.UNPROCESSABLE_ENTITY,
            })
        }

        const serviceReportCreated = {
            id: 999,
            creation_date: newDateFormatted(),
            status: StatusReport.enum.IN_PROGRESS,
            ...payloadParsed.data,
        }

        return HttpResponse.json(serviceReportCreated, {
            status: StatusCodes.CREATED,
        })
    },
)
