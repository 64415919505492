import { useEffect, useState } from 'react'
import { withRouter } from 'react-router-dom'
import { RecoilRoot } from 'recoil'
import { makeStyles, useMediaQuery, useTheme } from '@material-ui/core'
import '@fontsource/nunito/400.css'
import '@fontsource/nunito/700.css'

import { Dialog, Flex } from './ui'
import { Routes } from 'app/Routes'
import { Header } from './components/app/Header'
import { Sidebar } from './components/app/Sidebar'
import { GlobalSnackbar } from 'app/components/app'

import { urls } from './utils/constants'
import { joinStyles } from './utils/theme'

const useStyles = makeStyles({
    content: {
        overflow: 'hidden',
        flex: 'auto',
        justifyContent: 'unset',
        alignItems: 'unset',
    },

    dialog: {
        justifyContent: 'unset',
        alignItems: 'unset',
    },

    main: {
        overflow: 'auto',
        flex: 'auto',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
    },

    padding: {
        padding: '10px',
    },
})

const App = () => {
    const classes = useStyles()
    const theme = useTheme()

    const isMoreThanLg = useMediaQuery(theme.breakpoints.up('lg'))

    const [showSidebar, setShowSidebar] = useState(isMoreThanLg)

    const pathname = window.location.pathname

    const emptyPageLayoutRoutes = [
        urls.auth.LOGIN,
        `${urls.auth.LOGIN}/`,

        urls.auth.IMPERSONATE,
        `${urls.auth.IMPERSONATE}/`,

        urls.ERROR_404,
        `${urls.ERROR_404}/`,

        urls.ERROR_500,
        `${urls.ERROR_500}/`,
    ]

    const isFullPageLayoutRoute = !emptyPageLayoutRoutes.includes(pathname)

    const toggleShowSidebar = () => {
        setShowSidebar(!showSidebar)
    }

    // Trigger the LG breakpoint change
    useEffect(() => {
        setShowSidebar(isMoreThanLg)
    }, [isMoreThanLg])

    // Trigger the page change
    useEffect(() => {
        if (!isMoreThanLg) {
            setShowSidebar(false)
        }
    }, [pathname])

    return (
        <RecoilRoot>
            {/* Global snackbar */}
            <GlobalSnackbar />

            {/* Header */}
            {!!isFullPageLayoutRoute && (
                <Header toggleShowSidebar={toggleShowSidebar} />
            )}

            <Flex className={classes.content}>
                {/* Sidebar */}
                {!!isFullPageLayoutRoute && !!showSidebar && isMoreThanLg && (
                    <Sidebar />
                )}

                {!!isFullPageLayoutRoute && !!showSidebar && !isMoreThanLg && (
                    <Dialog
                        className={classes.dialog}
                        onClose={() => {
                            setShowSidebar(false)
                        }}
                        open
                    >
                        <Sidebar />
                    </Dialog>
                )}

                {/* Routes */}
                <main
                    className={joinStyles(
                        classes.main,
                        isFullPageLayoutRoute && classes.padding,
                    )}
                >
                    <Routes />
                </main>
            </Flex>
        </RecoilRoot>
    )
}

export default withRouter(App)
