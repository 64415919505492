import { makeStyles } from '@material-ui/core'

import { TableCell, TableRow } from 'app/ui'
import { palette } from 'app/utils/theme'

const useStyles = makeStyles(() => ({
    title: {
        fontSize: '1.5rem',
        fontWeight: 'bold',
        textAlign: 'left !important',
        color: palette.primary.main,
        backgroundColor: `hsl(${palette.secondaryRaw.main}, 0.2)`,
    },
}))

export const TableRowTitle = ({ label }) => {
    const classes = useStyles()

    return (
        <TableRow>
            <TableCell
                className={classes.title}
                colSpan={99}
            >
                {label}
            </TableCell>
        </TableRow>
    )
}
