import { TableCell, TableRow, CircularProgress } from 'app/ui'

export const TableRowLoading = ({ colSpan }) => {
    return (
        <TableRow>
            <TableCell colSpan={colSpan}>
                <CircularProgress isIndeterminate />
            </TableCell>
        </TableRow>
    )
}
