import { makeStyles } from '@material-ui/core'
import { joinStyles, palette } from 'app/utils/theme'

const useStyles = makeStyles({
    root: {
        padding: '10px',
        color: palette.secondary.main,
        fontSize: '1.1rem',
        textAlign: 'center',
        backgroundColor: palette.primary.main,
    },
})

export const TableFooterCell = ({ className, ...properties }) => {
    const classes = useStyles()

    return (
        <td
            className={joinStyles(classes.root, className)}
            {...properties}
        />
    )
}
