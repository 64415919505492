import { makeStyles } from '@material-ui/core'

import { joinStyles } from 'app/utils/theme'

const useStyles = makeStyles({
    root: {
        padding: '10px',
        textAlign: 'center',
    },

    content: {
        display: 'flex',
        justifyContent: 'center',
    },
})

export const TableCell = ({ className, ...properties }) => {
    const classes = useStyles()

    return (
        <td
            className={joinStyles(classes.root, className)}
            {...properties}
        />
    )
}
