import * as siteTypes from './types/list'
import * as zones from './zones/list'

import * as clients from 'app/mocks/data/park/clients/list'
import * as lambdas from 'app/mocks/data/users/konsole/lambdas'

import { Country } from 'app/types/shared/park/site'

export const siteStandard1 = {
    id: 1,
    name: 'Carrefour Bruxelles',
    description: '',
    customer_name: clients.client1,
    site_type: siteTypes.siteType1,
    zone: zones.zone1,
    address: '2 Rue de la Madeleine',
    postal_code: 1000,
    city: 'Bruxelles',
    country: Country.enum.BELGIUM,
    building_floor: '',
    latitude: 50.8462237,
    longitude: 4.3547765,
    further_information: '',
    supply_days: 'mo,we,fr',
    opening_hours: '09:00:00',
    closing_hours: '19:00:00',
    supervisor: lambdas.lambda1,
}

export const siteStandard2 = {
    id: 2,
    name: 'Carrefour Market Eurocontrol',
    description: '',
    customer_name: clients.client1,
    site_type: siteTypes.siteType1,
    zone: zones.zone2,
    address: '40 rue du marché aux poulets',
    postal_code: 1000,
    city: 'Bruxelles',
    country: Country.enum.BELGIUM,
    building_floor: '',
    latitude: 50.8486451,
    longitude: 4.3512768,
    further_information: '',
    supply_days: 'tu,th,sa',
    opening_hours: '09:00:00',
    closing_hours: '20:00:00',
    supervisor: lambdas.lambda2,
}

export const siteStandard3 = {
    id: 3,
    name: 'Enedis Vannes',
    description: '',
    customer_name: clients.client1,
    site_type: siteTypes.siteType1,
    zone: zones.zone3,
    address: '9 rue des cerisiers',
    postal_code: 91090,
    city: 'Lisses',
    country: Country.enum.FRANCE,
    building_floor: '',
    latitude: 48.5966024,
    longitude: 2.4357425,
    further_information: '',
    supply_days: 'mo',
    opening_hours: '09:00:00',
    closing_hours: '19:00:00',
}

export const siteStandard4 = {
    id: 4,
    name: 'ethik',
    description: '',
    customer_name: clients.client1,
    site_type: siteTypes.siteType36,
    zone: zones.zone3,
    address: '103 Rue Charles Michels',
    postal_code: 93200,
    city: 'St Denis',
    country: Country.enum.FRANCE,
    building_floor: '',
    latitude: 48.9286312,
    longitude: 2.343926418704376,
    further_information: '',
    supply_days: 'mo,tu,we,th,fr,sa',
    opening_hours: '11:20:00',
    closing_hours: '11:20:00',
}

export const siteStandard5 = {
    id: 5,
    name: 'e-thik Show Room',
    description: 'Pizza',
    customer_name: clients.client1,
    site_type: siteTypes.siteType35,
    zone: zones.zone4,
    address: '9 Rue des Cerisiers',
    postal_code: 91090,
    city: 'Lisses',
    country: Country.enum.FRANCE,
    building_floor: '',
    latitude: 48.5966024,
    longitude: 2.4357425,
    further_information: '',
    supply_days: 'mo,we,fr,th',
}

export const siteStandard6 = {
    id: 6,
    name: 'FAC DE SCIENCES  Bat A',
    description: '',
    customer_name: clients.client2,
    site_type: siteTypes.siteType33,
    zone: zones.zone2,
    address: '9',
    postal_code: 75000,
    city: 'Paris',
    country: Country.enum.FRANCE,
    building_floor: '',
    latitude: 48.8534951,
    longitude: 2.3483915,
    further_information: '',
    supply_days: 'mo,tu,we,th,fr,sa,su',
}

export const siteStandard7 = {
    id: 7,
    name: 'FAC DE SCIENCES  Bat E',
    description: '',
    customer_name: clients.client2,
    zone: zones.zone4,
    address: '3',
    postal_code: 75000,
    city: 'Paris',
    country: Country.enum.FRANCE,
    building_floor: '',
    latitude: 48.84669875,
    longitude: 2.252604434669352,
    further_information: '',
    supply_days: 'mo,tu,we,th,fr,sa,su',
    opening_hours: '08:00:00',
    closing_hours: '17:00:00',
}

export const siteStandard8 = {
    id: 8,
    name: 'FAC DE SCIENCES  Bat L',
    description: '',
    customer_name: clients.client3,
    site_type: siteTypes.siteType33,
    zone: zones.zone2,
    address: '6',
    postal_code: 75000,
    city: 'Paris',
    country: Country.enum.FRANCE,
    building_floor: '',
    latitude: 48.8534951,
    longitude: 2.3483915,
    further_information: '',
    supply_days: 'mo,tu,we,th,fr,sa,su',
    opening_hours: '08:00:00',
    closing_hours: '17:00:00',
}

export const siteStandard9 = {
    id: 9,
    name: 'Free Go chez JLP',
    description: '',
    customer_name: clients.client3,
    site_type: siteTypes.siteType1,
    zone: zones.zone1,
    address: 'la borde',
    postal_code: 85600,
    city: 'Montaigu en vendée',
    country: Country.enum.FRANCE,
    building_floor: '',
    latitude: 46.937024050000005,
    longitude: -1.2442653609259362,
    further_information: '',
    supply_days: 'mo,tu,we,th,fr',
}

export const siteStandard10 = {
    id: 10,
    name: 'Gare du Nord',
    description: 'Site de test 2',
    customer_name: clients.client3,
    site_type: siteTypes.siteType34,
    zone: zones.zone4,
    address: 'rue du nord',
    postal_code: 59000,
    city: 'Lille',
    country: Country.enum.FRANCE,
    latitude: 50.6693441,
    longitude: 2.9709494,
    supply_days: 'mo,sa',
}

export const siteStandard11 = {
    id: 11,
    name: 'graillance',
    description: '',
    customer_name: clients.client11,
    site_type: siteTypes.siteType4,
    zone: zones.zone5,
    address: '27 Rue Amédée Fraguier',
    postal_code: 91250,
    city: 'Tigery',
    country: Country.enum.FRANCE,
    building_floor: '',
    latitude: 48.6367067,
    longitude: 2.5102622,
    further_information: '',
    supply_days: 'mo',
    supervisor: lambdas.lambda7,
}

export const siteStandard12 = {
    id: 12,
    name: 'KIS',
    description: '',
    customer_name: clients.client11,
    site_type: siteTypes.siteType4,
    zone: zones.zone6,
    address: '7 Rue Jean-Pierre Timbaud',
    postal_code: 38130,
    city: 'Échirolles',
    country: Country.enum.FRANCE,
    building_floor: '',
    latitude: 45.139498,
    longitude: 5.7108647,
    further_information: '',
    supply_days: 'mo,tu,we,th,fr',
    supervisor: lambdas.lambda7,
}

export const siteStandard13 = {
    id: 13,
    name: 'Labo',
    description: 'Frigo de test e-thik',
    customer_name: clients.client12,
    site_type: siteTypes.siteType3,
    zone: zones.zone5,
    address: '1 rue Jules Serval',
    postal_code: 69259,
    city: 'Vénissieux',
    country: Country.enum.FRANCE,
    building_floor: '',
    latitude: 45.7076999,
    longitude: 4.8727913,
    further_information: '',
    supply_days: 'mo,tu,we,th,fr,sa,su',
    supervisor: lambdas.lambda3,
}

export const siteStandard14 = {
    id: 14,
    name: 'Mairie de Marcoussis',
    description: '',
    customer_name: clients.client12,
    site_type: siteTypes.siteType37,
    zone: zones.zone9,
    address: '10 rue des deux puits',
    postal_code: 91460,
    city: 'marcoussis',
    country: Country.enum.FRANCE,
    building_floor: '',
    latitude: 48.6395969,
    longitude: 2.227146,
    further_information: '',
    supply_days: 'mo',
}

export const siteStandard15 = {
    id: 15,
    name: 'Salon Europain',
    description: '',
    customer_name: clients.client13,
    site_type: siteTypes.siteType4,
    zone: zones.zone7,
    address: 'Porte de Versailles',
    postal_code: 750108,
    city: 'Paris',
    country: Country.enum.FRANCE,
    building_floor: '',
    latitude: 48.8324338,
    longitude: 2.2879537,
    further_information: '',
    supply_days: 'su,sa,fr,th,mo,tu',
}

export const siteStandard16 = {
    id: 16,
    name: 'sdf',
    description: '',
    customer_name: clients.client16,
    site_type: siteTypes.siteType5,
    zone: zones.zone8,
    address: 's',
    postal_code: 65432,
    city: 'sdf',
    country: Country.enum.FRANCE,
    building_floor: '',
    latitude: 25.30575475,
    longitude: 60.63166752453536,
    further_information: '',
    supply_days: 'mo',
}

export const siteStandard17 = {
    id: 17,
    name: 'Serbotel',
    description: '',
    customer_name: clients.client16,
    site_type: siteTypes.siteType5,
    zone: zones.zone33,
    address: 'PArc des expositions',
    postal_code: 44000,
    city: 'NAntes',
    country: Country.enum.FRANCE,
    building_floor: '',
    latitude: 47.21976565,
    longitude: -1.542945387088528,
    further_information: '',
    supply_days: 'mo,tu,we,th,fr,sa,su',
}

export const siteStandard18 = {
    id: 18,
    name: 'JP Morgan',
    description: '',
    customer_name: clients.client17,
    site_type: siteTypes.siteType5,
    zone: zones.zone34,
    address: '9 rue des cerisiers',
    postal_code: 91090,
    city: 'Lisses',
    country: Country.enum.FRANCE,
    building_floor: '',
    latitude: 48.5966024,
    longitude: 2.4357425,
    further_information: '',
    supply_days: 'mo,tu,we,th,fr',
}

export const siteStandard19 = {
    id: 19,
    name: 'Bijou',
    description: 'test',
    customer_name: clients.client17,
    site_type: siteTypes.siteType5,
    zone: zones.zone35,
    address: '13 All. François Mitterrand',
    postal_code: 49100,
    city: 'ANGERS',
    country: Country.enum.FRANCE,
    building_floor: '',
    latitude: 47.4786424,
    longitude: -0.5491213,
    further_information: '',
    supply_days: 'mo,tu,we,th,fr,sa,su',
}

export const siteStandard20 = {
    id: 20,
    name: 'Hospital de la Salpestrière',
    description: 'WebHook for the credit refill of Konvives',
    customer_name: clients.client18,
    site_type: siteTypes.siteType38,
    zone: zones.zone35,
    address: 'Av. Minerve 21',
    postal_code: 1450,
    city: 'Chastre',
    country: Country.enum.BELGIUM,
    building_floor: '',
    latitude: 50.606682500000005,
    longitude: 4.643459914745515,
    further_information: '',
    supply_days: 'mo,tu,we,fr,th,sa,su',
}

export const siteStandard21 = {
    id: 21,
    name: 'Renault Douai',
    description: 'WebHook for the credit refill of Konvives',
    customer_name: clients.client21,
    site_type: siteTypes.siteType8,
    zone: zones.zone10,
    address: 'Av. Minerve 21',
    postal_code: 1450,
    city: 'Chastre',
    country: Country.enum.BELGIUM,
    building_floor: '',
    latitude: 50.606682500000005,
    longitude: 4.643459914745515,
    further_information: '',
    supply_days: 'mo,tu,we,fr,th,sa,su',
}
