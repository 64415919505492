import * as terminalsStandard from 'app/mocks/data/machines/all/standard/terminals'
import * as sites from 'app/mocks/data/park/sites/linked'

export const terminal1 = {
    ...terminalsStandard.terminalStandard1,
    site: sites.site1,
}

export const terminal2 = {
    ...terminalsStandard.terminalStandard2,
    site: sites.site11,
}

export const terminal3 = {
    ...terminalsStandard.terminalStandard3,
    site: sites.site16,
}
