import { makeStyles } from '@material-ui/core'

import { TableCell, TableRow } from 'app/ui'

const useStyles = makeStyles(() => ({
    empty: {
        fontSize: '1.5rem',
    },
}))

export const TableRowText = ({ label }) => {
    const classes = useStyles()

    return (
        <TableRow>
            <TableCell
                className={classes.empty}
                colSpan={99}
            >
                {label}
            </TableCell>
        </TableRow>
    )
}
