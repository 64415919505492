import * as managers from 'app/mocks/data/park/managers/list'
import { permissions } from 'app/utils/constants'

export const profile1 = {
    id: 1,
    name: 'Gestionnaire',
    client: managers.manager1,
    permissions: [
        // Machine relations
        { codename: permissions.machines.machineRelations.READ },

        // Planograms
        { codename: permissions.machines.planograms.READ },
        { codename: permissions.machines.planograms.UPDATE },
    ],
    description:
        'Technicien avec des droits de remplissage du planogramme  sur les machines.',
}

export const profile2 = {
    id: 2,
    name: 'Profile2',
    client: managers.manager1,
    description:
        'Utilisateur avec un droit de technicien et de sous-gestionnaire lui permettant de créer des sites, des machines ainsi que de pouvoir étiqueter des produits.',
    permissions: [
        // Clients
        { codename: permissions.park.clients.CREATE },
        { codename: permissions.park.clients.READ },
        { codename: permissions.park.clients.UPDATE },
        { codename: permissions.park.clients.DELETE },

        // All machines
        { codename: permissions.machines.all.CREATE },
        { codename: permissions.machines.all.READ },
        { codename: permissions.machines.all.UPDATE },
        { codename: permissions.machines.all.DELETE },
    ],
}

export const profile3 = {
    id: 3,
    name: 'Profile3',
    client: managers.manager1,
    permissions: [
        // Marketing images
        { codename: permissions.machines.marketingImages.CREATE },
        { codename: permissions.machines.marketingImages.READ },
        { codename: permissions.machines.marketingImages.DELETE },

        // Marketing videos
        { codename: permissions.machines.marketingVideos.CREATE },
        { codename: permissions.machines.marketingVideos.READ },
        { codename: permissions.machines.marketingVideos.DELETE },
    ],
}

export const profile4 = {
    id: 4,
    name: 'Profile4',
    client: managers.manager7,
    permissions: [
        // Sites
        { codename: permissions.park.sites.CREATE },
        { codename: permissions.park.sites.READ },
        { codename: permissions.park.sites.UPDATE },
        { codename: permissions.park.sites.DELETE },

        // Stock
        { codename: permissions.machines.stock.READ },
    ],
}

export const profile5 = {
    id: 5,
    name: 'Profile5',
    client: managers.manager3,
    description: 'Gestionnaire supérieur.',
    permissions: [
        // Managers
        { codename: permissions.park.managers.CREATE },
        { codename: permissions.park.managers.READ },
        { codename: permissions.park.managers.DELETE },

        // Planograms
        { codename: permissions.machines.planograms.UPDATE },

        // Deliveries
        { codename: permissions.operations.deliveries.READ },
    ],
}

export const profile6 = {
    id: 6,
    name: 'Profile6',
    client: managers.manager1,
    description: 'Utilisateur du service compta.',
    permissions: [
        // Konvives
        { codename: permissions.users.konvives.READ },
        { codename: permissions.users.konvives.UPDATE },
    ],
}

export const profile7 = {
    id: 7,
    name: 'Profile7',
    client: managers.manager9,
    permissions: [
        // Profiles
        { codename: permissions.users.profiles.CREATE },
        { codename: permissions.users.profiles.READ },
        { codename: permissions.users.profiles.UPDATE },
        { codename: permissions.users.profiles.DELETE },
    ],
}

export const profile8 = {
    id: 8,
    name: 'Profile8',
    client: managers.manager13,
    permissions: [
        // Profiles
        { codename: permissions.users.profiles.CREATE },
        { codename: permissions.users.profiles.READ },
        { codename: permissions.users.profiles.UPDATE },
        { codename: permissions.users.profiles.DELETE },
    ],
}

export const profile9 = {
    id: 9,
    name: 'Profile9',
    client: managers.manager7,
    description:
        'Service personnalisation des sites clients ainsi que service juridique.',
    permissions: [
        // Managers
        { codename: permissions.park.managers.CREATE },
        { codename: permissions.park.managers.READ },
        { codename: permissions.park.managers.UPDATE },
        { codename: permissions.park.managers.DELETE },

        // Allergens
        { codename: permissions.settings.allergens.CREATE },
        { codename: permissions.settings.allergens.READ },
        { codename: permissions.settings.allergens.READ },
        { codename: permissions.settings.allergens.DELETE },
    ],
}

export const profile10 = {
    id: 10,
    name: 'Profile10',
    client: managers.manager21,
    permissions: [
        // Managers
        { codename: permissions.park.managers.CREATE },
        { codename: permissions.park.managers.READ },
        { codename: permissions.park.managers.UPDATE },
        { codename: permissions.park.managers.DELETE },

        // Product categories settings
        { codename: permissions.settings.productCategories.CREATE },
        { codename: permissions.settings.productCategories.READ },
        { codename: permissions.settings.productCategories.READ },
        { codename: permissions.settings.productCategories.DELETE },
    ],
}

export const profile11 = {
    id: 11,
    name: 'Profile11',
    client: managers.manager5,
    description: 'Service personnalisation et création produits 2eme étage.',
    permissions: [
        // Managers
        { codename: permissions.park.managers.CREATE },
        { codename: permissions.park.managers.READ },
        { codename: permissions.park.managers.UPDATE },
        { codename: permissions.park.managers.DELETE },

        // Subcategory products
        { codename: permissions.settings.subcategoryProducts.CREATE },
        { codename: permissions.settings.subcategoryProducts.READ },
        { codename: permissions.settings.subcategoryProducts.UPDATE },
        { codename: permissions.settings.subcategoryProducts.DELETE },
    ],
}

export const profile12 = {
    id: 12,
    name: 'Profile12',
    client: managers.manager18,
    permissions: [
        // Managers
        { codename: permissions.park.managers.CREATE },
        { codename: permissions.park.managers.READ },
        { codename: permissions.park.managers.UPDATE },
        { codename: permissions.park.managers.DELETE },

        // Theme
        { codename: permissions.settings.theme.UPDATE },

        // Email
        { codename: permissions.settings.email.CREATE },
        { codename: permissions.settings.email.READ },
        { codename: permissions.settings.email.UPDATE },
    ],
}

export const profile13 = {
    id: 13,
    name: 'Profile13',
    client: managers.manager3,
    permissions: [
        // Disputes
        { codename: permissions.transactions.disputes.READ },

        // Products disputed
        { codename: permissions.transactions.disputedProducts.READ },

        // Refills
        { codename: permissions.transactions.refills.READ },

        // Refunds
        { codename: permissions.transactions.refunds.READ },

        // Sales
        { codename: permissions.transactions.sales.READ },
    ],
}

export const profile14 = {
    id: 14,
    name: 'Profile14',
    client: managers.manager5,
    permissions: [
        // Disputes
        { codename: permissions.transactions.disputes.READ },
        { codename: permissions.transactions.disputes.UPDATE },

        // Machine settings
        { codename: permissions.machines.machineOptions.CREATE },
        { codename: permissions.machines.machineOptions.READ },
        { codename: permissions.machines.machineOptions.UPDATE },
        { codename: permissions.machines.machineOptions.DELETE },
    ],
}

export const profile15 = {
    id: 15,
    name: 'Profile15',
    client: managers.manager3,
    permissions: [
        // Refills
        { codename: permissions.transactions.refills.READ },

        // Sites
        { codename: permissions.park.sites.CREATE },
        { codename: permissions.park.sites.READ },
        { codename: permissions.park.sites.UPDATE },

        // Discounts
        { codename: permissions.catalogue.discounts.READ },
        { codename: permissions.catalogue.discountsPriority.UPDATE },

        // Theme
        { codename: permissions.settings.theme.CREATE },
        { codename: permissions.settings.theme.READ },
    ],
}

export const profile16 = {
    id: 16,
    name: 'Profile16',
    client: managers.manager8,
    permissions: [
        // Sales
        { codename: permissions.transactions.sales.READ },

        // Machine marketing
        { codename: permissions.machines.marketingImages.CREATE },
        { codename: permissions.machines.marketingImages.READ },
        { codename: permissions.machines.marketingVideos.UPDATE },

        // Technicians
        { codename: permissions.users.technicians.CREATE },
        { codename: permissions.users.technicians.DELETE },

        // Zones
        { codename: permissions.settings.zones.READ },
        { codename: permissions.settings.zones.DELETE },
    ],
}

export const profile17 = {
    id: 17,
    name: 'Profile17',
    client: managers.manager12,
    permissions: [
        // Disputes products
        { codename: permissions.transactions.disputedProducts.READ },

        // All machine permissions
        { codename: permissions.machines.all.CREATE },
        { codename: permissions.machines.all.READ },
        { codename: permissions.machines.all.UPDATE },
        { codename: permissions.machines.all.DELETE },

        // Price tables
        { codename: permissions.catalogue.priceTable.CREATE },
        { codename: permissions.catalogue.priceTable.UPDATE },

        // Email
        { codename: permissions.settings.email.CREATE },
        { codename: permissions.settings.email.UPDATE },
    ],
}

export const profile18 = {
    id: 18,
    name: 'Profile18',
    client: managers.manager2,
    permissions: [
        // Deliveries
        { codename: permissions.operations.deliveries.READ },

        // Machine relations
        { codename: permissions.machines.machineRelations.CREATE },
        { codename: permissions.machines.machineRelations.READ },

        // Discounts priority
        { codename: permissions.catalogue.discountsPriority.DELETE },

        // Zones
        { codename: permissions.settings.zones.UPDATE },
    ],
}

export const profile19 = {
    id: 19,
    name: 'Profile19',
    client: managers.manager6,
    permissions: [
        // Technical data
        { codename: permissions.machines.technicalData.CREATE },
        { codename: permissions.machines.technicalData.DELETE },

        // Interventions
        { codename: permissions.operations.interventions.READ },

        // Site types
        { codename: permissions.settings.siteTypes.CREATE },
        { codename: permissions.settings.siteTypes.READ },
    ],
}

export const profile20 = {
    id: 20,
    name: 'Profile20',
    client: managers.manager9,
    permissions: [
        // Incidents
        { codename: permissions.operations.incidents.READ },

        // Planograms
        { codename: permissions.machines.planograms.READ },
        { codename: permissions.machines.planograms.UPDATE },

        // Ticket
        { codename: permissions.settings.ticket.READ },
        { codename: permissions.settings.ticket.UPDATE },
    ],
}

export const profile21 = {
    id: 21,
    name: 'Profile21',
    client: managers.manager4,
    permissions: [
        // Encoding reports
        { codename: permissions.operations.encodedProducts.READ },

        // Marketing images
        { codename: permissions.machines.marketingImages.UPDATE },

        // Product categories
        { codename: permissions.settings.productCategories.CREATE },
        { codename: permissions.settings.productCategories.DELETE },
    ],
}

export const profile22 = {
    id: 22,
    name: 'Profile22',
    client: managers.manager1,
    permissions: [
        // Refunds
        { codename: permissions.transactions.refunds.READ },

        // Machine settings
        { codename: permissions.machines.machineOptions.READ },
        { codename: permissions.machines.machineOptions.UPDATE },

        // Suppliers
        { codename: permissions.catalogue.suppliers.CREATE },
        { codename: permissions.catalogue.suppliers.READ },
    ],
}

export const profile23 = {
    id: 23,
    name: 'Profile23',
    client: managers.manager7,
    permissions: [
        // Sales
        { codename: permissions.transactions.sales.READ },

        // Subcategory products
        { codename: permissions.settings.subcategoryProducts.UPDATE },
        { codename: permissions.settings.subcategoryProducts.DELETE },

        // Delivery men
        { codename: permissions.users.deliveryMen.READ },
        { codename: permissions.users.deliveryMen.CREATE },
    ],
}

export const profile24 = {
    id: 24,
    name: 'Profile24',
    client: managers.manager10,
    permissions: [
        // Stock
        { codename: permissions.machines.stock.READ },

        // Price line
        { codename: permissions.catalogue.priceLine.UPDATE },
        { codename: permissions.catalogue.priceLine.DELETE },

        // Theme
        { codename: permissions.settings.theme.READ },
    ],
}

export const profile25 = {
    id: 25,
    name: 'Profile25',
    client: managers.manager14,
    permissions: [
        // Disputes
        { codename: permissions.transactions.disputes.READ },

        // Marketing videos
        { codename: permissions.machines.marketingVideos.CREATE },

        // Clients
        { codename: permissions.park.clients.CREATE },
        { codename: permissions.park.clients.UPDATE },
    ],
}

export const profile26 = {
    id: 26,
    name: 'Profile26',
    client: managers.manager11,
    permissions: [
        // Destocking
        { codename: permissions.operations.destocking.DELETE },

        // Zones
        { codename: permissions.settings.zones.READ },
        { codename: permissions.settings.zones.CREATE },

        // Profiles
        { codename: permissions.users.profiles.UPDATE },
    ],
}

export const profile27 = {
    id: 27,
    name: 'Profile27',
    client: managers.manager13,
    permissions: [
        // Konvives
        { codename: permissions.users.konvives.READ },
        { codename: permissions.users.konvives.UPDATE },

        // Price tables
        { codename: permissions.catalogue.priceTable.CREATE },
        { codename: permissions.catalogue.priceTable.UPDATE },

        // Sites
        { codename: permissions.park.sites.READ },
        { codename: permissions.park.sites.UPDATE },
    ],
}

export const profile28 = {
    id: 28,
    name: 'Profile28',
    client: managers.manager15,
    permissions: [
        // Disputed products
        { codename: permissions.transactions.disputedProducts.READ },

        // Products
        { codename: permissions.catalogue.products.CREATE },
        { codename: permissions.catalogue.products.READ },

        // Email
        { codename: permissions.settings.email.UPDATE },
    ],
}

export const profile29 = {
    id: 29,
    name: 'Profile29',
    client: managers.manager16,
    permissions: [
        // Refills
        { codename: permissions.transactions.refills.READ },

        // Allergens
        { codename: permissions.settings.allergens.CREATE },
        { codename: permissions.settings.allergens.DELETE },

        // Technicians
        { codename: permissions.users.technicians.READ },
        { codename: permissions.users.technicians.UPDATE },
    ],
}

export const profile30 = {
    id: 30,
    name: 'Profile30',
    client: managers.manager17,
    permissions: [
        // Managers
        { codename: permissions.park.managers.CREATE },
        { codename: permissions.park.managers.UPDATE },

        // Deliveries
        { codename: permissions.operations.deliveries.READ },

        // Discounts
        { codename: permissions.catalogue.discounts.UPDATE },
        { codename: permissions.catalogue.discounts.DELETE },
    ],
}

export const profile31 = {
    id: 31,
    name: 'Profile31',
    client: managers.manager18,
    permissions: [
        // Incidents
        { codename: permissions.operations.incidents.READ },

        // Machine relations
        { codename: permissions.machines.machineRelations.CREATE },
        { codename: permissions.machines.machineRelations.DELETE },

        // Subcategory products
        { codename: permissions.settings.subcategoryProducts.READ },
        { codename: permissions.settings.subcategoryProducts.UPDATE },
    ],
}

export const profile32 = {
    id: 32,
    name: 'Profile32',
    client: managers.manager19,
    permissions: [
        // Encoding reports
        { codename: permissions.operations.encodedProducts.READ },

        // Product categories
        { codename: permissions.settings.productCategories.CREATE },
        { codename: permissions.settings.productCategories.READ },

        // Profiles
        { codename: permissions.users.profiles.UPDATE },
        { codename: permissions.users.profiles.DELETE },
    ],
}

export const profile33 = {
    id: 33,
    name: 'Profile33',
    client: managers.manager20,
    permissions: [
        // Refunds
        { codename: permissions.transactions.refunds.READ },

        // Theme
        { codename: permissions.settings.theme.CREATE },
        { codename: permissions.settings.theme.DELETE },

        // Price line
        { codename: permissions.catalogue.priceLine.READ },
        { codename: permissions.catalogue.priceLine.UPDATE },
    ],
}

export const profile34 = {
    id: 34,
    name: 'Profile34',
    client: managers.manager21,
    permissions: [
        // Stock
        { codename: permissions.machines.stock.READ },

        // Marketing images
        { codename: permissions.machines.marketingImages.UPDATE },
        { codename: permissions.machines.marketingImages.DELETE },

        // Clients
        { codename: permissions.park.clients.CREATE },
        { codename: permissions.park.clients.READ },
    ],
}

export const profile35 = {
    id: 35,
    name: 'Profile35',
    client: managers.manager1,
    permissions: [
        // Price table
        { codename: permissions.catalogue.priceTable.UPDATE },
        { codename: permissions.catalogue.priceTable.DELETE },

        // Interventions
        { codename: permissions.operations.interventions.READ },

        // Delivery men
        { codename: permissions.users.deliveryMen.CREATE },
        { codename: permissions.users.deliveryMen.DELETE },
    ],
}

export const profile36 = {
    id: 36,
    name: 'Profile36',
    client: managers.manager2,
    permissions: [
        // Zones
        { codename: permissions.settings.zones.CREATE },
        { codename: permissions.settings.zones.UPDATE },

        // Konsole users
        { codename: permissions.users.konsole.READ },
        { codename: permissions.users.konsole.UPDATE },
    ],
}

export const profile37 = {
    id: 37,
    name: 'Profile37',
    client: managers.manager3,
    permissions: [
        // Sales
        { codename: permissions.transactions.sales.READ },

        // Planograms
        { codename: permissions.machines.planograms.CREATE },
        { codename: permissions.machines.planograms.DELETE },

        // Suppliers
        { codename: permissions.catalogue.suppliers.READ },
        { codename: permissions.catalogue.suppliers.UPDATE },
    ],
}
