import { z } from 'zod'

import { StatusReport } from 'app/types/reports'
import { Client } from 'app/types/shared/park/client'
import { Konvive } from 'app/types/users/konvive'
import { Service } from 'app/types/users/service'

/** The konvive with some data added for the service report. */
export const KonviveReport = Konvive.pick({
    id: true,
    first_name: true,
    last_name: true,
    email: true,
    card_number: true,
}).extend({
    employer_contribution_total: z.number().positive(),
    amount_total: z.number().positive(),
})

/** The service with some data added for the service report. */
export const ServiceReportObject = Service.pick({
    id: true,
    name: true,
}).extend({
    employer_contribution_total: z.number().positive(),
    amount_total: z.number().positive(),
    konvives: KonviveReport.array().min(1),
})

/** The client with some data added for the service report. */
export const ClientReportObject = Client.pick({
    id: true,
    name: true,
}).extend({
    employer_contribution_total: z.number().positive(),
    amount_total: z.number().positive(),
    services: ServiceReportObject.array(),
})

/** Service report with all data. */
export const ServiceReport = z.object({
    /** The ID of the service report. */
    id: z.number().positive(),

    /** The creation date of the service report. */
    creation_date: z.string().datetime({ offset: true }),

    /** The start date of the service report interval. */
    date_start: z.string().date(),

    /** The start end of the service report interval. */
    date_end: z.string().date(),

    /** The filters chosen when service report is created. */
    filter_service: Service.array(),

    /** The client related to the service report. */
    client: ClientReportObject,
})

/** The service report object send in the service report list. */
export const ServiceReportList = ServiceReport.pick({
    id: true,
    creation_date: true,
}).extend({
    status: StatusReport,
    client: Client.pick({
        id: true,
        name: true,
    }),
})

/** The data sent to create a service report. */
export const ServicesReportPayload = ServiceReport.pick({
    date_start: true,
    date_end: true,
}).and(
    z.union([
        z.object({
            services: Service.shape.id.array().min(1),
        }),
        z.object({
            client: Client.shape.id,
        }),
    ]),
)
