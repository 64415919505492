import * as managers from 'app/mocks/data/park/managers/list'

export const siteType1 = {
    id: 1,
    name: 'Centre Commercial',
    client: managers.manager1,
    priority: 1,
}

export const siteType2 = {
    id: 2,
    name: 'Supermarché',
    client: managers.manager1,
    priority: 2,
}

export const siteType3 = {
    id: 3,
    name: 'Hypermarché',
    client: managers.manager1,
    priority: 3,
}

export const siteType4 = {
    id: 4,
    name: 'Épicerie',
    client: managers.manager1,
    priority: 4,
}

export const siteType5 = {
    id: 5,
    name: 'Drive',
    client: managers.manager1,
    priority: 5,
}

export const siteType6 = {
    id: 6,
    name: 'Magasin de Proximité',
    client: managers.manager1,
    priority: 6,
}

export const siteType7 = {
    id: 7,
    name: 'Marché Couvert',
    client: managers.manager1,
    priority: 7,
}

export const siteType8 = {
    id: 8,
    name: 'Grande Surface Spécialisée Alimentaire',
    client: managers.manager1,
    priority: 8,
}

export const siteType9 = {
    id: 9,
    name: 'Grand Magasin Ameublement',
    client: managers.manager2,
    priority: 1,
}

export const siteType10 = {
    id: 10,
    name: 'Boutique Électroménager',
    client: managers.manager2,
    priority: 2,
}

export const siteType11 = {
    id: 11,
    name: 'Magasin de Décoration',
    client: managers.manager2,
    priority: 3,
}

export const siteType12 = {
    id: 12,
    name: 'Showroom Design',
    client: managers.manager2,
    priority: 4,
}

export const siteType13 = {
    id: 13,
    name: 'Boutique Linge de Maison',
    client: managers.manager2,
    priority: 5,
}

export const siteType14 = {
    id: 14,
    name: 'Magasin Bricolage',
    client: managers.manager2,
    priority: 6,
}

export const siteType15 = {
    id: 15,
    name: 'Centre Cuisine & Bains',
    client: managers.manager2,
    priority: 7,
}

export const siteType16 = {
    id: 16,
    name: 'Magasin Luminaires',
    client: managers.manager2,
    priority: 8,
}

export const siteType17 = {
    id: 17,
    name: 'Boutique Art de la Table',
    client: managers.manager2,
    priority: 9,
}

export const siteType18 = {
    id: 18,
    name: 'Centre Jardin & Outdoor',
    client: managers.manager2,
    priority: 10,
}

export const siteType19 = {
    id: 19,
    name: 'Grand Magasin Mode',
    client: managers.manager3,
    priority: 1,
}

export const siteType20 = {
    id: 20,
    name: 'Boutique de Luxe',
    client: managers.manager3,
    priority: 2,
}

export const siteType21 = {
    id: 21,
    name: 'Centre Sport & Fitness',
    client: managers.manager3,
    priority: 3,
}

export const siteType22 = {
    id: 22,
    name: 'Magasin de Chaussures',
    client: managers.manager3,
    priority: 4,
}

export const siteType23 = {
    id: 23,
    name: 'Boutique Accessoires',
    client: managers.manager3,
    priority: 5,
}

export const siteType24 = {
    id: 24,
    name: 'Parfumerie',
    client: managers.manager3,
    priority: 6,
}

export const siteType25 = {
    id: 25,
    name: 'Centre Beauté & Cosmétiques',
    client: managers.manager3,
    priority: 7,
}

export const siteType26 = {
    id: 26,
    name: 'Boutique Enfants',
    client: managers.manager3,
    priority: 8,
}

export const siteType27 = {
    id: 27,
    name: 'Magasin de Sport',
    client: managers.manager3,
    priority: 9,
}

export const siteType28 = {
    id: 28,
    name: 'Outlet Mode & Accessoires',
    client: managers.manager3,
    priority: 10,
}

export const siteType29 = {
    id: 29,
    name: 'Magasin High-Tech',
    client: managers.manager4,
    priority: 1,
}

export const siteType30 = {
    id: 30,
    name: 'Centre Informatique',
    client: managers.manager4,
    priority: 2,
}

export const siteType31 = {
    id: 31,
    name: 'Boutique Téléphonie',
    client: managers.manager4,
    priority: 3,
}

export const siteType32 = {
    id: 32,
    name: 'Magasin Audio-Vidéo',
    client: managers.manager4,
    priority: 4,
}

export const siteType33 = {
    id: 33,
    name: 'Centre Gaming',
    client: managers.manager4,
    priority: 5,
}

export const siteType34 = {
    id: 34,
    name: 'Boutique Accessoires High-Tech',
    client: managers.manager4,
    priority: 6,
}

export const siteType35 = {
    id: 35,
    name: 'Centre SAV Électronique',
    client: managers.manager4,
    priority: 7,
}

export const siteType36 = {
    id: 36,
    name: 'Magasin Photo & Vidéo',
    client: managers.manager4,
    priority: 8,
}

export const siteType37 = {
    id: 37,
    name: 'Boutique Objets Connectés',
    client: managers.manager4,
    priority: 9,
}

export const siteType38 = {
    id: 38,
    name: 'Centre Domotique',
    client: managers.manager4,
    priority: 10,
}

export const siteType39 = {
    id: 39,
    name: 'Magasin Robotique',
    client: managers.manager4,
    priority: 11,
}

export const siteType40 = {
    id: 40,
    name: 'Boutique Réalité Virtuelle',
    client: managers.manager4,
    priority: 12,
}

export const siteType41 = {
    id: 41,
    name: 'Centre Formation Tech',
    client: managers.manager4,
    priority: 13,
}

export const siteType42 = {
    id: 42,
    name: 'Magasin Composants Informatiques',
    client: managers.manager4,
    priority: 14,
}

export const siteType43 = {
    id: 43,
    name: 'Outlet High-Tech',
    client: managers.manager4,
    priority: 15,
}
