import { makeStyles } from '@material-ui/core'
import { palette, themeMultiply, various } from 'app/utils/theme'

const useStyles = makeStyles({
    root: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        margin: various.margin,
        padding: themeMultiply(various.padding, 2),
        backgroundColor: palette.background.primary,
        borderRadius: various.borderRadius,
        boxShadow: various.boxShadow.component,
    },
})

export const Card = ({ className = '', column, ...props }) => {
    const classes = useStyles()

    return (
        <div
            className={`${classes.root} ${className}`}
            {...props}
        />
    )
}
