import * as lockersDryStandard from 'app/mocks/data/machines/all/standard/lockersDry'
import * as sites from 'app/mocks/data/park/sites/linked'

export const lockerDry1 = {
    ...lockersDryStandard.lockerDryStandard1,
    site: sites.site1,
}

export const lockerDry2 = {
    ...lockersDryStandard.lockerDryStandard2,
    site: sites.site11,
}

export const lockerDry3 = {
    ...lockersDryStandard.lockerDryStandard3,
    site: sites.site16,
}
