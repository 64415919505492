import * as managers from 'app/mocks/data/park/managers/list'

export const testRights = {
    id: 5,
    name: 'test droits',
    description: 'Ceci est un test',
    client: managers.manager1.id,
    permissions: [
        {
            id: 10,
            name: 'Can change group',
            codename: 'change_group',
            content_type: 3,
        },
        {
            id: 12,
            name: 'Can view group',
            codename: 'view_group',
            content_type: 3,
        },
        {
            id: 8,
            name: 'Can view permission',
            codename: 'view_permission',
            content_type: 2,
        },
        {
            id: 16,
            name: 'Can view user',
            codename: 'view_user',
            content_type: 4,
        },
        {
            id: 168,
            name: 'Can view Allergen',
            codename: 'view_allergens',
            content_type: 42,
        },
        {
            id: 124,
            name: 'Can view category client',
            codename: 'view_categoryclient',
            content_type: 31,
        },
        {
            id: 56,
            name: 'Can view CategoryProduct',
            codename: 'view_categoryproduct',
            content_type: 14,
        },
        {
            id: 48,
            name: 'Can view Client',
            codename: 'view_client',
            content_type: 12,
        },
        {
            id: 88,
            name: 'Can view Delivery',
            codename: 'view_delivery',
            content_type: 22,
        },
        {
            id: 44,
            name: 'Can view Deliveryman',
            codename: 'view_deliveryman',
            content_type: 11,
        },
        {
            id: 80,
            name: 'Can view MachineUser',
            codename: 'view_machineuser',
            content_type: 20,
        },
        {
            id: 52,
            name: 'Can view Product',
            codename: 'view_product',
            content_type: 13,
        },
        {
            id: 93,
            name: 'Can add Site',
            codename: 'add_site',
            content_type: 24,
        },
        {
            id: 96,
            name: 'Can view Site',
            codename: 'view_site',
            content_type: 24,
        },
        {
            id: 183,
            name: 'Can delete Site Type',
            codename: 'delete_sitetype',
            content_type: 46,
        },
        {
            id: 184,
            name: 'Can view Site Type',
            codename: 'view_sitetype',
            content_type: 46,
        },
        {
            id: 180,
            name: 'Can view SubCategoryProduct',
            codename: 'view_subcategoryproduct',
            content_type: 45,
        },
        {
            id: 140,
            name: 'Can view product supplier',
            codename: 'view_supplierproduct',
            content_type: 35,
        },
        {
            id: 185,
            name: 'Can add Zone',
            codename: 'add_zone',
            content_type: 47,
        },
        {
            id: 188,
            name: 'Can view Zone',
            codename: 'view_zone',
            content_type: 47,
        },
        {
            id: 25,
            name: 'Can add Machine',
            codename: 'add_machine',
            content_type: 7,
        },
        {
            id: 27,
            name: 'Can delete Machine',
            codename: 'delete_machine',
            content_type: 7,
        },
        {
            id: 28,
            name: 'Can view Machine',
            codename: 'view_machine',
            content_type: 7,
        },
        {
            id: 156,
            name: 'Can view MachineOption',
            codename: 'view_machineoption',
            content_type: 39,
        },
        {
            id: 32,
            name: 'Can view TechnicalData',
            codename: 'view_technicaldata',
            content_type: 8,
        },
        {
            id: 144,
            name: 'Can view price line',
            codename: 'view_priceline',
            content_type: 36,
        },
        {
            id: 146,
            name: 'Can change price table',
            codename: 'change_pricetable',
            content_type: 37,
        },
        {
            id: 147,
            name: 'Can delete price table',
            codename: 'delete_pricetable',
            content_type: 37,
        },
        {
            id: 148,
            name: 'Can view price table',
            codename: 'view_pricetable',
            content_type: 37,
        },
        {
            id: 112,
            name: 'Can view Sale',
            codename: 'view_sale',
            content_type: 28,
        },
    ],
}
