import * as managers from 'app/mocks/data/park/managers/list'

export const zone1 = {
    id: 1,
    name: 'Centre',
    client: managers.manager1,
    priority: 1,
}

export const zone2 = {
    id: 2,
    name: 'Nord',
    client: managers.manager1,
    priority: 2,
}

export const zone3 = {
    id: 3,
    name: 'Sud',
    client: managers.manager1,
    priority: 3,
}

export const zone4 = {
    id: 4,
    name: 'Est',
    client: managers.manager1,
    priority: 4,
}

export const zone5 = {
    id: 5,
    name: 'Ouest',
    client: managers.manager1,
    priority: 5,
}

export const zone6 = {
    id: 6,
    name: 'Sud-Est',
    client: managers.manager1,
    priority: 6,
}

export const zone7 = {
    id: 7,
    name: 'Sud-Ouest',
    client: managers.manager1,
    priority: 7,
}

export const zone8 = {
    id: 8,
    name: 'Nord-Est',
    client: managers.manager1,
    priority: 8,
}

export const zone9 = {
    id: 9,
    name: 'Nord-Ouest',
    client: managers.manager1,
    priority: 9,
}

export const zone10 = {
    id: 10,
    name: 'Centre',
    client: managers.manager2,
    priority: 1,
}

export const zone11 = {
    id: 11,
    name: 'Nord',
    client: managers.manager2,
    priority: 2,
}

export const zone12 = {
    id: 12,
    name: 'Sud',
    client: managers.manager2,
    priority: 3,
}

export const zone13 = {
    id: 13,
    name: 'Est',
    client: managers.manager2,
    priority: 4,
}

export const zone14 = {
    id: 14,
    name: 'Ouest',
    client: managers.manager2,
    priority: 5,
}

export const zone15 = {
    id: 15,
    name: 'Sud-Est',
    client: managers.manager2,
    priority: 6,
}

export const zone16 = {
    id: 16,
    name: 'Sud-Ouest',
    client: managers.manager2,
    priority: 7,
}

export const zone17 = {
    id: 17,
    name: 'Nord-Est',
    client: managers.manager2,
    priority: 8,
}

export const zone18 = {
    id: 18,
    name: 'Nord-Ouest',
    client: managers.manager2,
    priority: 9,
}

// Zones Manager 3
export const zone19 = {
    id: 19,
    name: 'Centre',
    client: managers.manager3,
    priority: 1,
}

export const zone20 = {
    id: 20,
    name: 'Nord',
    client: managers.manager3,
    priority: 2,
}

export const zone21 = {
    id: 21,
    name: 'Sud',
    client: managers.manager3,
    priority: 3,
}

export const zone22 = {
    id: 22,
    name: 'Est',
    client: managers.manager3,
    priority: 4,
}

export const zone23 = {
    id: 23,
    name: 'Ouest',
    client: managers.manager3,
    priority: 5,
}

export const zone24 = {
    id: 24,
    name: 'Sud-Est',
    client: managers.manager3,
    priority: 6,
}

export const zone25 = {
    id: 25,
    name: 'Sud-Ouest',
    client: managers.manager3,
    priority: 7,
}

export const zone26 = {
    id: 26,
    name: 'Nord-Est',
    client: managers.manager3,
    priority: 8,
}

export const zone27 = {
    id: 27,
    name: 'Nord-Ouest',
    client: managers.manager3,
    priority: 9,
}

// Zones Manager 4
export const zone28 = {
    id: 28,
    name: 'Centre',
    client: managers.manager4,
    priority: 1,
}

export const zone29 = {
    id: 29,
    name: 'Nord',
    client: managers.manager4,
    priority: 2,
}

export const zone30 = {
    id: 30,
    name: 'Sud',
    client: managers.manager4,
    priority: 3,
}

export const zone31 = {
    id: 31,
    name: 'Est',
    client: managers.manager4,
    priority: 4,
}

export const zone32 = {
    id: 32,
    name: 'Ouest',
    client: managers.manager4,
    priority: 5,
}

export const zone33 = {
    id: 33,
    name: 'Sud-Est',
    client: managers.manager4,
    priority: 6,
}

export const zone34 = {
    id: 34,
    name: 'Sud-Ouest',
    client: managers.manager4,
    priority: 7,
}

export const zone35 = {
    id: 35,
    name: 'Nord-Est',
    client: managers.manager4,
    priority: 8,
}

export const zone36 = {
    id: 36,
    name: 'Nord-Ouest',
    client: managers.manager4,
    priority: 9,
}
