import * as machineRelationsShort from './short'
import * as planograms from 'app/mocks/data/machines/planograms/full'

export const machineRelation1 = {
    ...machineRelationsShort.machineRelationShort1,
    distributors: [planograms.planogram1],
    lockers: [planograms.planogram2, planograms.planogram3],
}

export const machineRelation2 = {
    weighted_fridges: [planograms.planogram4],
}

export const machineRelation3 = {
    weighted_fridges: [planograms.planogram5],
}

export const machineRelation4 = {
    distributors: [planograms.planogram6],
    lockers: [planograms.planogram7, planograms.planogram8],
}

export const machineRelation5 = {
    weighted_fridges: [planograms.planogram9],
}

export const machineRelation6 = {
    lockers: [planograms.planogram10],
}
