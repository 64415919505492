import { useState } from 'react'
import { useHistory } from 'react-router'
import { useTranslation } from 'react-i18next'
import { Avatar, Button, Link, makeStyles } from '@material-ui/core'
import { BsLayoutSidebarInset } from 'react-icons/bs'
import { MdSupportAgent } from 'react-icons/md'

import { Dialog, Flex } from 'app/ui'
import { ChooseLanguage } from './ChooseLanguage'

import { urls } from 'app/utils/constants'
import { assets } from 'app/utils/constants'
import { palette, various } from 'app/utils/theme'
import { CustomerService } from './CustomerService'

const useStyles = makeStyles((theme) => ({
    root: {
        zIndex: 100,
        padding: `10px ${various.padding}`,
        backgroundColor: palette.primary.main,
        boxShadow: various.boxShadow.component,
        [theme.breakpoints.up('lg')]: {
            padding: '10px 10',
        },
        [theme.breakpoints.up('xl')]: {
            padding: '10px 20%',
        },
    },

    header: {
        justifyContent: 'space-between',
        width: '100%',
    },

    toggle: {
        marginLeft: various.margin,
        color: palette.secondary.main,
    },

    homepage: {
        cursor: 'pointer',
        display: 'flex',
    },

    logo: {
        width: '50px',
        height: '50px',
    },

    group: {
        display: 'flex',
    },

    setting: {
        height: '45px',
        margin: '0 5px',
        color: palette.secondary.main,
        border: 'unset',
        borderRadius: various.borderRadius,
    },

    avatar: {
        height: '25px',
        width: '25px',
    },

    name: {
        display: 'none',
        paddingLeft: '10px',
        [theme.breakpoints.up('md')]: {
            display: 'unset',
        },
    },
}))

export const Header = ({ toggleShowSidebar }) => {
    const classes = useStyles()
    const history = useHistory()
    const { t } = useTranslation()

    const [wantsCustomerService, setWantsCustomerService] = useState(false)

    const username = localStorage.getItem('username')

    return (
        <header className={classes.root}>
            <Flex className={classes.header}>
                <Flex>
                    {/* Homepage button */}
                    <Link
                        className={classes.homepage}
                        variant='button'
                        onClick={() => {
                            history.push(urls.DASHBOARD)
                        }}
                    >
                        <img
                            className={classes.logo}
                            src={assets.ethik.KONSOLE_WHITE}
                            alt='Logo'
                        />
                    </Link>

                    {/* Toggle sidebar */}
                    <Button
                        className={classes.toggle}
                        onClick={() => {
                            toggleShowSidebar()
                        }}
                    >
                        <BsLayoutSidebarInset size='1.5rem' />
                    </Button>
                </Flex>

                {/* Settings */}
                <Flex className={classes.group}>
                    {/* Customer service */}
                    <Button
                        className={classes.setting}
                        variant='outlined'
                        onClick={() => {
                            setWantsCustomerService(true)
                        }}
                    >
                        <MdSupportAgent size='2rem' />

                        <div className={classes.name}>
                            {t('customerService')}
                        </div>
                    </Button>

                    {/* Dialog customer service */}
                    <Dialog
                        open={wantsCustomerService}
                        onClose={() => {
                            setWantsCustomerService(false)
                        }}
                    >
                        <CustomerService />
                    </Dialog>

                    {/* Language */}
                    <ChooseLanguage />

                    {/* Account */}
                    <Button
                        className={classes.setting}
                        variant='outlined'
                        onClick={() => {
                            history.push(urls.ACCOUNT)
                        }}
                    >
                        <Avatar className={classes.avatar} />

                        <div className={classes.name}>{username}</div>
                    </Button>
                </Flex>
            </Flex>
        </header>
    )
}
