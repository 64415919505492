import i18n from 'i18n'

import { Locale } from 'app/types/shared/language'

/** Get the language name of the locale. */
export const languages = {
    'en-GB': 'English',
    'es-ES': 'Español',
    'fr-FR': 'Français',
}

/** Get an array of locale and its name sorted by name. */
export const languageList = () => {
    const list = [
        {
            locale: Locale.enum['en-GB'],
            name: i18n?.t('english') ?? '',
        },
        {
            locale: Locale.enum['es-ES'],
            name: i18n?.t('spanish') ?? '',
        },
        {
            locale: Locale.enum['fr-FR'],
            name: i18n?.t('french') ?? '',
        },
    ]

    list.sort((a, b) => a.name.localeCompare(b.name))

    return list
}
