import { http, HttpResponse } from 'msw'

import { api, links } from 'app/utils/constants'
import { allManagers } from 'app/mocks/data/park/managers'
import { allServices } from 'app/mocks/data/users/services'
import { allClients } from 'app/mocks/data/park/clients'

export const postCreationLink = http.post(
    `${process.env.REACT_APP_API_ADDRESS}/${api.users.konvives.CREATION_LINK}`,
    async ({ request }) => {
        const { client, manager, service } = await request.json()

        let managerName
        let clientCode
        let serviceCode
        let link = ''

        // Get the manager
        if (manager) {
            const managerFound = allManagers.find(
                (managerToTest) => managerToTest.id === manager,
            )

            managerName = managerFound ? managerFound.name : allManagers[0].name
        }

        // Get the client
        if (client) {
            const clientFound = allClients.find(
                (clientToTest) => clientToTest.id === client,
            )

            if (clientFound) clientCode = clientFound.name
        }

        // Get the service
        if (service) {
            const serviceFound = allServices.find(
                (serviceToTest) => serviceToTest.id === service,
            )
            if (serviceFound) serviceCode = serviceFound.name
        }

        // Get the link to send
        link = `${links.KONVIVES}/${managerName}/register`

        const urlSearchParams = new URLSearchParams({
            ...(!!clientCode && { client: clientCode }),
            ...(!!serviceCode && { service: serviceCode }),
        })

        if (urlSearchParams.toString()) link += `?${urlSearchParams.toString()}`

        return HttpResponse.json({
            link,
        })
    },
)
