import { lazy, Suspense } from 'react'
import jwt_decode from 'jwt-decode'
import { Redirect, Route, Switch } from 'react-router-dom'

import { CircularProgress } from './ui'

import { urls } from 'app/utils/constants'
import { history } from 'app/utils/app/history'

/****************
 *              *
 *    Common    *
 *              *
 ****************/
const Dashboard = lazy(() => import('app/pages'))
const Account = lazy(() => import('app/pages/account'))
const Error404 = lazy(() => import('app/pages/404'))
const Error500 = lazy(() => import('app/pages/500'))

/**************
 *            *
 *    Auth    *
 *            *
 **************/

const Login = lazy(() => import('app/pages/auth/login'))
const Impersonate = lazy(() => import('app/pages/auth/impersonate'))
const ResetPassword = lazy(() => import('app/pages/auth/reset-password'))

/**************
 *            *
 *    Park    *
 *            *
 **************/

// Managers
const ManagerList = lazy(() => import('app/pages/park/managers'))
const ManagerCreate = lazy(() => import('app/pages/park/managers/create'))
const ManagerDetails = lazy(() => import('app/pages/park/managers/[id]'))

// Clients
const ClientList = lazy(() => import('app/pages/park/clients'))
const ClientCreate = lazy(() => import('app/pages/park/clients/create'))
const ClientDetails = lazy(() => import('app/pages/park/clients/[id]'))

// Sites
const Sites = lazy(() => import('app/pages/park/sites'))
const SitesMachineStock = lazy(
    () => import('app/pages/park/sites/machine-stock'),
)
const SitesMachineTechnicalData = lazy(
    () => import('app/pages/park/sites/machine-technical-data'),
)
const SitesMarketingMachine = lazy(
    () => import('app/pages/park/sites/marketing-machine'),
)

/******************
 *                *
 *    Machines    *
 *                *
 ******************/

// All
const All = lazy(() => import('app/pages/machines/all'))

// Microwaves
const MicrowaveList = lazy(() => import('app/pages/machines/microwaves'))
const MicrowaveCreate = lazy(
    () => import('app/pages/machines/microwaves/create'),
)
const MicrowaveDetails = lazy(
    () => import('app/pages/machines/microwaves/[id]'),
)
const MicrowaveSettings = lazy(
    () => import('app/pages/machines/microwaves/[id]/settings'),
)

// Planograms
const PlanogramList = lazy(() => import('app/pages/machines/planograms'))
const PlanogramCreate = lazy(
    () => import('app/pages/machines/planograms/create/[id]'),
)
const PlanogramDetails = lazy(
    () => import('app/pages/machines/planograms/[id]'),
)
const PlanogramProductInfo = lazy(
    () => import('app/pages/machines/planograms/[id]/product-info'),
)
const PlanogramTechnicalData = lazy(
    () => import('app/pages/machines/planograms/[id]/technical-data'),
)

/*******************
 *                 *
 *    Catalogue    *
 *                 *
 *******************/

// Suppliers
const SupplierList = lazy(() => import('app/pages/catalogue/suppliers'))
const SupplierCreate = lazy(
    () => import('app/pages/catalogue/suppliers/create'),
)
const SupplierDetails = lazy(() => import('app/pages/catalogue/suppliers/[id]'))

// Products
const Products = lazy(() => import('app/pages/catalogue/products'))

// Price tables
const PriceTables = lazy(() => import('app/pages/catalogue/price-tables'))

// Discounts
const Discounts = lazy(() => import('app/pages/catalogue/discounts'))

// Stats
const Stats = lazy(() => import('app/pages/catalogue/stats'))

/**********************
 *                    *
 *    Transactions    *
 *                    *
 **********************/

// Sales
const Sales = lazy(() => import('app/pages/transactions/sales'))

// Refunds
const Refunds = lazy(() => import('app/pages/transactions/refunds'))

// Refills
const Refills = lazy(() => import('app/pages/transactions/refills'))

// Blocked cards
const BlockedCards = lazy(() => import('app/pages/transactions/blocked-cards'))

/********************
 *                  *
 *    Operations    *
 *                  *
 ********************/

// Deliveries
const Deliveries = lazy(() => import('app/pages/operations/deliveries'))

// Incidents
const Incidents = lazy(() => import('app/pages/operations/incidents'))

// Incidents RabbitMQ
const IncidentsRabbitmq = lazy(
    () => import('app/pages/operations/incidents-rabbitmq'),
)

// Encoded products
const EncodedProducts = lazy(
    () => import('app/pages/operations/encoded-products'),
)

// Destocking
const Destocking = lazy(() => import('app/pages/operations/destocking'))

// Interventions
const Interventions = lazy(() => import('app/pages/operations/interventions'))

/*****************
 *               *
 *    Reports    *
 *               *
 *****************/

// Supply
const Supply = lazy(() => import('app/pages/reports/supply'))

// Service reports
const ServiceReportList = lazy(() => import('app/pages/reports/service'))

const ServiceReportCreate = lazy(
    () => import('app/pages/reports/service/create'),
)

const ServiceReportDetails = lazy(
    () => import('app/pages/reports/service/[id]'),
)

/***************
 *             *
 *    Users    *
 *             *
 ***************/

// Profiles
const ProfileList = lazy(() => import('app/pages/users/profiles'))
const ProfileCreate = lazy(() => import('app/pages/users/profiles/create'))
const ProfileDetails = lazy(() => import('app/pages/users/profiles/[id]'))

// Users Konsole
const UsersKonsoleList = lazy(() => import('app/pages/users/konsole'))
const UsersKonsoleCreate = lazy(() => import('app/pages/users/konsole/create'))
const UsersKonsoleDetails = lazy(() => import('app/pages/users/konsole/[id]'))

// Technicians
const TechniciansList = lazy(() => import('app/pages/users/technicians'))
const TechnicianCreate = lazy(
    () => import('app/pages/users/technicians/create'),
)
const TechnicianDetails = lazy(() => import('app/pages/users/technicians/[id]'))

// Delivery men
const DeliveryMenList = lazy(() => import('app/pages/users/delivery-men'))
const DeliveryMenCreate = lazy(
    () => import('app/pages/users/delivery-men/create'),
)
const DeliveryMenDetails = lazy(
    () => import('app/pages/users/delivery-men/[id]'),
)

// Services
const ServiceList = lazy(() => import('app/pages/users/services'))
const ServiceCreate = lazy(() => import('app/pages/users/services/create'))
const ServiceDetails = lazy(() => import('app/pages/users/services/[id]'))

// Users Konvives
const Konvives = lazy(() => import('app/pages/users/konvives'))

/******************
 *                *
 *    Settings    *
 *                *
 ******************/

const Theme = lazy(() => import('app/pages/settings/theme'))
const Categories = lazy(() => import('app/pages/settings/categories'))
const Email = lazy(() => import('app/pages/settings/email'))
const Ticket = lazy(() => import('app/pages/settings/ticket'))

const GuardedRoute = ({ component: Component, authed, ...properties }) => (
    <Route
        history={history}
        render={(props) =>
            authed === true ? (
                <Component {...props} />
            ) : (
                <Redirect to={urls.auth.LOGIN} />
            )
        }
        {...properties}
    />
)

export const isAuthenticated = () => {
    const token = localStorage.getItem('refresh_token')
    let isExpired = false

    if (token) {
        const decodedToken = jwt_decode(token)
        const dateNow = new Date()

        if (
            decodedToken.exp <
            parseInt(dateNow.getTime().toString().slice(0, -3))
        ) {
            isExpired = true
        }

        return !isExpired
    }

    if (!token) {
        return false
    }
}

export const Routes = () => {
    return (
        <Suspense fallback={<CircularProgress />}>
            <Switch>
                {/****************
                 *              *
                 *    Common    *
                 *              *
                 ****************/}

                {/* Homepage */}
                <GuardedRoute
                    path={urls.DASHBOARD}
                    component={Dashboard}
                    authed={isAuthenticated()}
                    exact
                />

                {/* Account */}
                <GuardedRoute
                    path={urls.ACCOUNT}
                    component={Account}
                    authed={isAuthenticated()}
                    exact
                />

                {/* Error 404 */}
                <Route
                    path={urls.ERROR_404}
                    component={Error404}
                    exact
                />

                {/* Error 500 */}
                <Route
                    path={urls.ERROR_500}
                    component={Error500}
                    exact
                />

                {/**************
                 *            *
                 *    Auth    *
                 *            *
                 **************/}

                <Route
                    path={urls.auth.LOGIN}
                    component={Login}
                    exact
                />

                <Route
                    path={urls.auth.IMPERSONATE}
                    component={Impersonate}
                    exact
                />

                <Route
                    path={urls.auth.RESET_PASSWORD}
                    component={ResetPassword}
                    exact
                />

                {/**************
                 *            *
                 *    Park    *
                 *            *
                 **************/}

                {/* Managers */}
                <GuardedRoute
                    path={urls.park.managers.LIST}
                    component={ManagerList}
                    authed={isAuthenticated()}
                    exact
                />

                <GuardedRoute
                    path={urls.park.managers.CREATE}
                    component={ManagerCreate}
                    authed={isAuthenticated()}
                    exact
                />

                <GuardedRoute
                    path={urls.park.managers.DETAILS}
                    component={ManagerDetails}
                    authed={isAuthenticated()}
                    exact
                />

                {/* Clients */}
                <GuardedRoute
                    path={urls.park.clients.LIST}
                    component={ClientList}
                    authed={isAuthenticated()}
                    exact
                />

                <GuardedRoute
                    path={urls.park.clients.CREATE}
                    component={ClientCreate}
                    authed={isAuthenticated()}
                    exact
                />

                <GuardedRoute
                    path={urls.park.clients.DETAILS}
                    component={ClientDetails}
                    authed={isAuthenticated()}
                    exact
                />

                {/* Sites */}
                <GuardedRoute
                    path={urls.park.sites.INDEX}
                    component={Sites}
                    authed={isAuthenticated()}
                    exact
                />

                <GuardedRoute
                    path={urls.park.sites.TECHNICAL_DATA}
                    component={SitesMachineTechnicalData}
                    authed={isAuthenticated()}
                    exact
                />

                <GuardedRoute
                    path={urls.park.sites.MACHINE_STOCK}
                    component={SitesMachineStock}
                    authed={isAuthenticated()}
                    exact
                />

                <GuardedRoute
                    path={urls.park.sites.MACHINE_MARKETING}
                    component={SitesMarketingMachine}
                    authed={isAuthenticated()}
                    exact
                />

                <GuardedRoute
                    path={urls.park.sites.DETAILS}
                    component={All}
                    authed={isAuthenticated()}
                    exact
                />

                {/******************
                 *                *
                 *    Machines    *
                 *                *
                 ******************/}

                {/* Machines */}
                <GuardedRoute
                    path={urls.machines.ALL}
                    component={All}
                    authed={isAuthenticated()}
                    exact
                />

                {/* Microwaves */}
                <GuardedRoute
                    path={urls.machines.microwaves.LIST}
                    component={MicrowaveList}
                    authed={isAuthenticated()}
                    exact
                />

                <GuardedRoute
                    path={urls.machines.microwaves.CREATE}
                    component={MicrowaveCreate}
                    authed={isAuthenticated()}
                    exact
                />

                <GuardedRoute
                    path={urls.machines.microwaves.DETAILS}
                    component={MicrowaveDetails}
                    authed={isAuthenticated()}
                    exact
                />

                <GuardedRoute
                    path={urls.machines.microwaves.SETTINGS}
                    component={MicrowaveSettings}
                    authed={isAuthenticated()}
                    exact
                />

                {/* Planograms */}
                <GuardedRoute
                    path={urls.machines.planograms.LIST}
                    component={PlanogramList}
                    authed={isAuthenticated()}
                    exact
                />

                <GuardedRoute
                    path={urls.machines.planograms.CREATE}
                    component={PlanogramCreate}
                    authed={isAuthenticated()}
                    exact
                />

                <GuardedRoute
                    path={urls.machines.planograms.DETAILS}
                    component={PlanogramDetails}
                    authed={isAuthenticated()}
                    exact
                />

                <GuardedRoute
                    path={urls.machines.planograms.PRODUCT_INFO}
                    component={PlanogramProductInfo}
                    authed={isAuthenticated()}
                    exact
                />

                <GuardedRoute
                    path={urls.machines.planograms.TECHNICAL_DATA}
                    component={PlanogramTechnicalData}
                    authed={isAuthenticated()}
                    exact
                />

                {/*******************
                 *                 *
                 *    Catalogue    *
                 *                 *
                 *******************/}

                {/* Suppliers */}
                <GuardedRoute
                    path={urls.catalogue.suppliers.LIST}
                    component={SupplierList}
                    authed={isAuthenticated()}
                    exact
                />

                <GuardedRoute
                    path={urls.catalogue.suppliers.CREATE}
                    component={SupplierCreate}
                    authed={isAuthenticated()}
                    exact
                />

                <GuardedRoute
                    path={urls.catalogue.suppliers.DETAILS}
                    component={SupplierDetails}
                    authed={isAuthenticated()}
                    exact
                />

                {/* Products */}
                <GuardedRoute
                    path={urls.catalogue.PRODUCTS}
                    component={Products}
                    authed={isAuthenticated()}
                    exact
                />

                {/* Price tables */}
                <GuardedRoute
                    path={urls.catalogue.PRICE_TABLES}
                    component={PriceTables}
                    authed={isAuthenticated()}
                    exact
                />

                {/* Discounts */}
                <GuardedRoute
                    path={urls.catalogue.DISCOUNTS}
                    component={Discounts}
                    authed={isAuthenticated()}
                    exact
                />

                {/* Stats */}
                <GuardedRoute
                    path={urls.catalogue.STATS}
                    component={Stats}
                    authed={isAuthenticated()}
                    exact
                />

                {/**********************
                 *                    *
                 *    Transactions    *
                 *                    *
                 **********************/}

                {/* Sales */}
                <GuardedRoute
                    path={urls.transactions.SALES}
                    component={Sales}
                    authed={isAuthenticated()}
                    exact
                />

                {/* Refunds */}
                <GuardedRoute
                    path={urls.transactions.REFUNDS}
                    component={Refunds}
                    authed={isAuthenticated()}
                    exact
                />

                {/* Refills */}
                <GuardedRoute
                    path={urls.transactions.REFILLS}
                    component={Refills}
                    authed={isAuthenticated()}
                    exact
                />

                {/* Blocked cards */}
                <GuardedRoute
                    path={urls.transactions.BLOCKED_CARDS}
                    component={BlockedCards}
                    authed={isAuthenticated()}
                    exact
                />

                {/********************
                 *                  *
                 *    Operations    *
                 *                  *
                 ********************/}

                {/* Deliveries */}
                <GuardedRoute
                    path={urls.operations.DELIVERIES}
                    component={Deliveries}
                    authed={isAuthenticated()}
                    exact
                />

                {/* Incidents */}
                <GuardedRoute
                    path={urls.operations.INCIDENTS}
                    component={Incidents}
                    authed={isAuthenticated()}
                    exact
                />

                {/* Incidents RabbitMQ */}
                <GuardedRoute
                    path={urls.operations.INCIDENTS_RABBITMQ}
                    component={IncidentsRabbitmq}
                    authed={isAuthenticated()}
                    exact
                />

                {/* Interventions */}
                <GuardedRoute
                    path={urls.operations.INTERVENTIONS}
                    component={Interventions}
                    authed={isAuthenticated()}
                    exact
                />

                {/* Encoded products */}
                <GuardedRoute
                    path={urls.operations.ENCODED_PRODUCTS}
                    component={EncodedProducts}
                    authed={isAuthenticated()}
                    exact
                />

                {/* Destocking */}
                <GuardedRoute
                    path={urls.operations.DESTOCKING}
                    component={Destocking}
                    authed={isAuthenticated()}
                    exact
                />

                {/*****************
                 *               *
                 *    Reports    *
                 *               *
                 *****************/}

                {/* Supply reports */}
                <GuardedRoute
                    path={urls.reports.SUPPLY}
                    component={Supply}
                    authed={isAuthenticated()}
                    exact
                />

                {/* Service reports */}
                <GuardedRoute
                    path={urls.reports.service.LIST}
                    component={ServiceReportList}
                    authed={isAuthenticated()}
                    exact
                />

                <GuardedRoute
                    path={urls.reports.service.CREATE}
                    component={ServiceReportCreate}
                    authed={isAuthenticated()}
                    exact
                />

                <GuardedRoute
                    path={urls.reports.service.DETAILS}
                    component={ServiceReportDetails}
                    authed={isAuthenticated()}
                    exact
                />

                {/***************
                 *             *
                 *    Users    *
                 *             *
                 ***************/}

                {/* Profiles */}
                <GuardedRoute
                    path={urls.users.profiles.CREATE}
                    component={ProfileCreate}
                    authed={isAuthenticated()}
                    exact
                />

                <GuardedRoute
                    path={urls.users.profiles.DETAILS}
                    component={ProfileDetails}
                    authed={isAuthenticated()}
                    exact
                />

                <GuardedRoute
                    path={urls.users.profiles.LIST}
                    component={ProfileList}
                    authed={isAuthenticated()}
                    exact
                />

                {/* Users Konsole */}
                <GuardedRoute
                    path={urls.users.konsole.LIST}
                    component={UsersKonsoleList}
                    authed={isAuthenticated()}
                    exact
                />

                <GuardedRoute
                    path={urls.users.konsole.CREATE}
                    component={UsersKonsoleCreate}
                    authed={isAuthenticated()}
                    exact
                />

                <GuardedRoute
                    path={urls.users.konsole.DETAILS}
                    component={UsersKonsoleDetails}
                    authed={isAuthenticated()}
                    exact
                />

                {/* Technicians */}
                <GuardedRoute
                    path={urls.users.technicians.LIST}
                    component={TechniciansList}
                    authed={isAuthenticated()}
                    exact
                />

                <GuardedRoute
                    path={urls.users.technicians.CREATE}
                    component={TechnicianCreate}
                    authed={isAuthenticated()}
                    exact
                />

                <GuardedRoute
                    path={urls.users.technicians.DETAILS}
                    component={TechnicianDetails}
                    authed={isAuthenticated()}
                    exact
                />

                {/* Delivery men */}
                <GuardedRoute
                    path={urls.users.deliveryMen.LIST}
                    component={DeliveryMenList}
                    authed={isAuthenticated()}
                    exact
                />

                <GuardedRoute
                    path={urls.users.deliveryMen.CREATE}
                    component={DeliveryMenCreate}
                    authed={isAuthenticated()}
                    exact
                />

                <GuardedRoute
                    path={urls.users.deliveryMen.DETAILS}
                    component={DeliveryMenDetails}
                    authed={isAuthenticated()}
                    exact
                />

                {/* Services */}
                <GuardedRoute
                    path={urls.users.services.LIST}
                    component={ServiceList}
                    authed={isAuthenticated()}
                    exact
                />

                <GuardedRoute
                    path={urls.users.services.CREATE}
                    component={ServiceCreate}
                    authed={isAuthenticated()}
                    exact
                />

                <GuardedRoute
                    path={urls.users.services.DETAILS}
                    component={ServiceDetails}
                    authed={isAuthenticated()}
                    exact
                />

                {/* Konvives */}
                <GuardedRoute
                    path={urls.users.KONVIVES}
                    component={Konvives}
                    authed={isAuthenticated()}
                    exact
                />

                {/******************
                 *                *
                 *    Settings    *
                 *                *
                 ******************/}

                {/* Theme */}
                <GuardedRoute
                    path={urls.settings.THEME}
                    component={Theme}
                    authed={isAuthenticated()}
                    exact
                />

                {/* Categories */}
                <GuardedRoute
                    path={urls.settings.CATEGORIES}
                    component={Categories}
                    authed={isAuthenticated()}
                    exact
                />

                {/* Email */}
                <GuardedRoute
                    path={urls.settings.EMAIL}
                    component={Email}
                    authed={isAuthenticated()}
                />

                {/* Ticket */}
                <GuardedRoute
                    path={urls.settings.TICKET}
                    component={Ticket}
                    authed={isAuthenticated()}
                    exact
                />

                <Redirect to={urls.DASHBOARD} />
            </Switch>
        </Suspense>
    )
}
