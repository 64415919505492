import * as clients from 'app/mocks/data/park/clients/list'
import { StatusReport } from 'app/types/reports'
import * as services from 'app/mocks/data/users/services/list'
import * as konvives from 'app/mocks/data/users/konvives/list'

export const serviceReport1 = {
    id: 1,
    creation_date: '2025-01-01T00:00:00',
    status: StatusReport.enum.DONE,
    date_start: '2024-12-28',
    date_end: '2024-12-31',
    filter_service: [
        { ...services.service1 },
        { ...services.service2 },
        { ...services.service3 },
        { ...services.service4 },
    ],
    client: {
        ...clients.client1,
        employer_contribution_total: 29,
        amount_total: 43,
        services: [
            {
                ...services.service1,
                employer_contribution_total: 9,
                amount_total: 12,
                konvives: [
                    {
                        ...konvives.konvive1,
                        employer_contribution_total: 4,
                        amount_total: 10,
                    },
                    {
                        ...konvives.konvive29,
                        employer_contribution_total: 5,
                        amount_total: 12,
                    },
                ],
            },
            {
                ...services.service2,
                employer_contribution_total: 1,
                amount_total: 7,
                konvives: [
                    {
                        ...konvives.konvive1,
                        employer_contribution_total: 1,
                        amount_total: 7,
                    },
                ],
            },
            {
                ...services.service3,
                employer_contribution_total: 9,
                amount_total: 9,
                konvives: [
                    {
                        ...konvives.konvive1,
                        employer_contribution_total: 6,
                        amount_total: 18,
                    },
                    {
                        ...konvives.konvive29,
                        employer_contribution_total: 3,
                        amount_total: 9,
                    },
                ],
            },
            {
                ...services.service4,
                employer_contribution_total: 10,
                amount_total: 15,
                konvives: [
                    {
                        ...konvives.konvive1,
                        employer_contribution_total: 10,
                        amount_total: 15,
                    },
                ],
            },
        ],
    },
}

export const serviceReport2 = {
    id: 2,
    creation_date: '2025-01-02T00:00:00',
    status: StatusReport.enum.DONE,
    date_start: '2024-12-01',
    date_end: '2025-01-02',
    filter_service: [],
    client: {
        ...clients.client2,
        employer_contribution_total: 18,
        amount_total: 12,
        services: [
            {
                ...services.service6,
                employer_contribution_total: 18,
                amount_total: 12,
                konvives: [
                    {
                        ...konvives.konvive1,
                        employer_contribution_total: 4,
                        amount_total: 10,
                    },
                    {
                        ...konvives.konvive29,
                        employer_contribution_total: 5,
                        amount_total: 12,
                    },
                ],
            },
        ],
    },
}

export const serviceReport3 = {
    id: 3,
    creation_date: '2025-01-03T00:00:00',
    status: StatusReport.enum.DONE,
    date_start: '2024-12-01',
    date_end: '2024-12-31',
    filter_service: [
        {
            ...services.service14,
        },
    ],
    client: {
        ...clients.client3,
        employer_contribution_total: 15,
        amount_total: 58,
        services: [
            {
                ...services.service14,
                employer_contribution_total: 15,
                amount_total: 58,
                konvives: [
                    {
                        ...konvives.konvive1,
                        employer_contribution_total: 12,
                        amount_total: 46,
                    },
                    {
                        ...konvives.konvive29,
                        employer_contribution_total: 3,
                        amount_total: 12,
                    },
                ],
            },
        ],
    },
}

export const serviceReport4 = {
    id: 4,
    creation_date: '2025-01-04T00:00:00',
    status: StatusReport.enum.DONE,
    date_start: '2024-11-01',
    date_end: '2025-01-04',
    filter_service: [
        { ...services.service1 },
        { ...services.service2 },
        { ...services.service3 },
        { ...services.service4 },
        { ...services.service23 },
    ],
    client: {
        ...clients.client1,
        employer_contribution_total: 46,
        amount_total: 62,
        services: [
            {
                ...services.service1,
                employer_contribution_total: 13,
                amount_total: 16,
                konvives: [
                    {
                        ...konvives.konvive1,
                        employer_contribution_total: 3,
                        amount_total: 12,
                    },
                    {
                        ...konvives.konvive29,
                        employer_contribution_total: 5,
                        amount_total: 12,
                    },
                    {
                        ...konvives.konvive30,
                        employer_contribution_total: 5,
                        amount_total: 16,
                    },
                ],
            },
            {
                ...services.service2,
                employer_contribution_total: 3,
                amount_total: 8,
                konvives: [
                    {
                        ...konvives.konvive1,
                        employer_contribution_total: 1,
                        amount_total: 6,
                    },
                    {
                        ...konvives.konvive29,
                        employer_contribution_total: 1,
                        amount_total: 9,
                    },
                    {
                        ...konvives.konvive30,
                        employer_contribution_total: 1,
                        amount_total: 8,
                    },
                ],
            },
            {
                ...services.service3,
                employer_contribution_total: 11,
                amount_total: 12,
                konvives: [
                    {
                        ...konvives.konvive1,
                        employer_contribution_total: 8,
                        amount_total: 22,
                    },
                    {
                        ...konvives.konvive29,
                        employer_contribution_total: 3,
                        amount_total: 12,
                    },
                ],
            },
            {
                ...services.service4,
                employer_contribution_total: 2,
                amount_total: 6,
                konvives: [
                    {
                        ...konvives.konvive1,
                        employer_contribution_total: 2,
                        amount_total: 6,
                    },
                ],
            },
            {
                ...services.service23,
                employer_contribution_total: 17,
                amount_total: 20,
                konvives: [
                    {
                        ...konvives.konvive1,
                        employer_contribution_total: 7,
                        amount_total: 23,
                    },
                    {
                        ...konvives.konvive29,
                        employer_contribution_total: 5,
                        amount_total: 12,
                    },
                    {
                        ...konvives.konvive30,
                        employer_contribution_total: 5,
                        amount_total: 20,
                    },
                ],
            },
        ],
    },
}

export const serviceReport5 = {
    id: 5,
    creation_date: '2025-01-05T00:00:00',
    status: StatusReport.enum.DONE,
    date_start: '2024-12-04',
    date_end: '2025-01-05',
    filter_service: [
        { ...services.service4 },
        { ...services.service7 },
        { ...services.service25 },
    ],
    client: {
        ...clients.client15,
        employer_contribution_total: 0,
        amount_total: 0,
        services: [],
    },
}

export const serviceReport6 = {
    id: 6,
    creation_date: '2025-01-06T00:00:00',
    status: StatusReport.enum.IN_PROGRESS,
    date_start: '2024-09-18',
    date_end: '2024-12-09',
    client: clients.client2,
}

export const serviceReport7 = {
    id: 7,
    creation_date: '2025-01-07T00:00:00',
    status: StatusReport.enum.IN_PROGRESS,
    date_start: '2024-09-30',
    date_end: '2024-10-19',
    client: clients.client16,
}

export const serviceReport8 = {
    id: 8,
    creation_date: '2025-01-08T00:00:00',
    status: StatusReport.enum.IN_PROGRESS,
    date_start: '2024-10-30',
    date_end: '2024-12-19',
    client: clients.client1,
}

export const serviceReport9 = {
    id: 9,
    creation_date: '2025-01-09T00:00:00',
    status: StatusReport.enum.IN_PROGRESS,
    date_start: '2024-09-01',
    date_end: '2024-11-11',
    client: clients.client15,
}

export const serviceReport10 = {
    id: 10,
    creation_date: '2025-01-10T00:00:00',
    status: StatusReport.enum.IN_PROGRESS,
    date_start: '2024-08-24',
    date_end: '2024-10-17',
    client: clients.client9,
}

export const serviceReport11 = {
    id: 11,
    creation_date: '2025-01-11T00:00:00',
    status: StatusReport.enum.IN_PROGRESS,
    date_start: '2024-12-03',
    date_end: '2025-01-05',
    client: clients.client11,
}

export const serviceReport12 = {
    id: 12,
    creation_date: '2025-01-12T00:00:00',
    status: StatusReport.enum.IN_PROGRESS,
    date_start: '2024-10-19',
    date_end: '2024-11-21',
    client: clients.client17,
}

export const serviceReport13 = {
    id: 13,
    creation_date: '2025-01-13T00:00:00',
    status: StatusReport.enum.IN_PROGRESS,
    date_start: '2024-09-23',
    date_end: '2024-12-30',
    client: clients.client9,
}

export const serviceReport14 = {
    id: 14,
    creation_date: '2025-01-14T00:00:00',
    status: StatusReport.enum.IN_PROGRESS,
    date_start: '2024-09-11',
    date_end: '2024-10-31',
    client: clients.client6,
}

export const serviceReport15 = {
    id: 15,
    creation_date: '2025-01-15T00:00:00',
    status: StatusReport.enum.IN_PROGRESS,
    date_start: '2024-10-18',
    date_end: '2024-11-14',
    client: clients.client14,
}

export const serviceReport16 = {
    id: 16,
    creation_date: '2025-01-16T00:00:00',
    status: StatusReport.enum.IN_PROGRESS,
    date_start: '2024-09-11',
    date_end: '2024-10-11',
    client: clients.client20,
}

export const serviceReport17 = {
    id: 17,
    creation_date: '2025-01-17T00:00:00',
    status: StatusReport.enum.IN_PROGRESS,
    date_start: '2024-11-25',
    date_end: '2024-12-09',
    client: clients.client13,
}

export const serviceReport18 = {
    id: 18,
    creation_date: '2025-01-18T00:00:00',
    status: StatusReport.enum.IN_PROGRESS,
    date_start: '2024-10-02',
    date_end: '2025-01-10',
    client: clients.client17,
}

export const serviceReport19 = {
    id: 19,
    creation_date: '2025-01-19T00:00:00',
    status: StatusReport.enum.IN_PROGRESS,
    date_start: '2024-09-20',
    date_end: '2024-10-18',
    client: clients.client21,
}

export const serviceReport20 = {
    id: 20,
    creation_date: '2025-01-20T00:00:00',
    status: StatusReport.enum.IN_PROGRESS,
    date_start: '2024-08-24',
    date_end: '2024-11-20',
    client: clients.client1,
}

export const serviceReport21 = {
    id: 21,
    creation_date: '2025-01-21T00:00:00',
    status: StatusReport.enum.IN_PROGRESS,
    date_start: '2024-10-19',
    date_end: '2024-11-03',
    client: clients.client12,
}

export const serviceReport22 = {
    id: 22,
    creation_date: '2025-01-22T00:00:00',
    status: StatusReport.enum.IN_PROGRESS,
    date_start: '2024-07-30',
    date_end: '2024-10-29',
    client: clients.client12,
}

export const serviceReport23 = {
    id: 23,
    creation_date: '2025-01-23T00:00:00',
    status: StatusReport.enum.IN_PROGRESS,
    date_start: '2024-12-17',
    date_end: '2024-12-26',
    client: clients.client2,
}

export const serviceReport24 = {
    id: 24,
    creation_date: '2025-01-24T00:00:00',
    status: StatusReport.enum.IN_PROGRESS,
    date_start: '2024-08-15',
    date_end: '2024-11-18',
    client: clients.client5,
}

export const serviceReport25 = {
    id: 25,
    creation_date: '2025-01-25T00:00:00',
    status: StatusReport.enum.IN_PROGRESS,
    date_start: '2024-11-11',
    date_end: '2024-11-16',
    client: clients.client16,
}

export const serviceReport26 = {
    id: 26,
    creation_date: '2025-01-26T00:00:00',
    status: StatusReport.enum.IN_PROGRESS,
    date_start: '2024-10-02',
    date_end: '2025-01-10',
    client: clients.client17,
}

export const serviceReport27 = {
    id: 27,
    creation_date: '2025-01-27T00:00:00',
    status: StatusReport.enum.IN_PROGRESS,
    date_start: '2024-10-02',
    date_end: '2025-01-10',
    client: clients.client10,
}

export const serviceReport28 = {
    id: 28,
    creation_date: '2025-01-28T00:00:00',
    status: StatusReport.enum.IN_PROGRESS,
    date_start: '2024-10-02',
    date_end: '2025-01-10',
    client: clients.client9,
}

export const serviceReport29 = {
    id: 29,
    creation_date: '2025-01-29T00:00:00',
    status: StatusReport.enum.IN_PROGRESS,
    date_start: '2024-10-02',
    date_end: '2025-01-10',
    client: clients.client14,
}

export const serviceReport30 = {
    id: 30,
    creation_date: '2025-01-30T00:00:00',
    status: StatusReport.enum.IN_PROGRESS,
    date_start: '2024-10-02',
    date_end: '2025-01-10',
    client: clients.client5,
}
